import React, { useState } from 'react'
import { Accordion, Card, Container } from 'react-bootstrap'
import './TaxiService.css'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TaxiService = () => {

    const [activeKey, setActiveKey] = useState(null); // Track which accordion is open

    const toggleActiveKey = (key) => {
        setActiveKey(activeKey === key ? null : key); // Toggle the active state
    };

    return (
        <section className='taxi-service'>
            <div className='container-fluid p-0'>
                <Container className='content'>
                    <h3 className='title'>Delhi to Agra Taxi Services</h3>

                    <p className='description'>
                        Being an Aggregator, CabBazar provides you enormous car options for your Delhi to Agra taxi booking. You can mention your specific preference, if any, while raising a taxi booking request. Our partnered taxi services providers have various cars available with them for Delhi to Agra taxi service.
                    </p>

                    <div className='row justify-content-center'>
                        <div className='col-lg-10'>
                            <Accordion activeKey={activeKey} onSelect={(key) => toggleActiveKey(key)} className="custom-accordion">


                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className="custom-header">
                                        01.What is the distance from Delhi to Agra by car?
                                    </Accordion.Header>
                                    <Accordion.Body className="custom-body">
                                        This is the content of the first accordion panel.
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className="custom-header" onClick={() => toggleActiveKey("1")}>
                                        02. How long does it take from Delhi to Agra by road?
                                    </Accordion.Header>
                                    <Accordion.Body className="custom-body">
                                        This is the content of the second accordion panel.
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className="custom-header">
                                        03.How long does it take from Delhi to Agra by road?
                                    </Accordion.Header>
                                    <Accordion.Body className="custom-body">
                                        This is the content of the third accordion panel.
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>
                    </div>


                </Container>
            </div>
        </section>
    )
}

export default TaxiService

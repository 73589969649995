import React, { useState } from "react";
import './Book_form.css'
import Form from 'react-bootstrap/Form';
import axios from "axios";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TimePicker } from "antd";

import Enter_phone_modal from "../../common-components/modals/Enter-phone/Enter_phone_modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Book_form = () => {

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ];

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [endDate2, setEndDate2] = useState();
  const [endDate3, setEndDate3] = useState();
  const [endDate4, setEndDate4] = useState();


  const [modalShow, setModalShow] = React.useState(false);

  const [showCityDiv, setShowCityDiv] = useState(false);
  const toggleCityDiv = () => {
    setShowCityDiv(prevState => !prevState);
  };


  return (
    <>
      <section className='Book_form_sec'>
        <div className='container'>
          <div className='banner-div'>
            <img className='banner' src={process.env.PUBLIC_URL + '/assets/images/booking-form/banner.png'} />
          </div>

          <div className='form-main-section'>
            <h6 className='formtitle'>One way Ride Booking</h6>
            <div className='row'>
              <div className='col-lg-10 mx-auto'>
                <div className='form-div'>
                  <Form>
                    {/* Outstation one way form start  */}
                    <div className="row">
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Source City</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Destination City</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Date Of Departure</Form.Label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            name="deadline"
                            id="deadline"
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Time Of Departure</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {/* Outstation one way form end  */}

                    <br></br> <br></br>

                    {/* Outstation round trip form start */}
                    <div className="row">
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Source City</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Destination City</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Date Of Departure</Form.Label>
                          <DatePicker
                            selected={endDate2}
                            onChange={(date) => setEndDate2(date)}
                            name="deadline"
                            id="deadline"
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Time Of Departure</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Returning the cab to</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Journey end date</Form.Label>
                          <DatePicker
                            selected={endDate3}
                            onChange={(date) => setEndDate3(date)}
                            name="deadline"
                            id="deadline"
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Journey end time</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {/* Outstation round trip form end */}

                    <br></br> <br></br>

                    {/* Outstation multicity form start */}
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Source City</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Destination City</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Date Of Departure</Form.Label>
                          <DatePicker
                            selected={endDate4}
                            onChange={(date) => setEndDate4(date)}
                            name="deadline"
                            id="deadline"
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Time Of Departure</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>

                      <div className="col-12 text-center mb-2">
                        <button type="button" className="addcitybtn btn" onClick={toggleCityDiv}>
                          Add City
                        </button>
                      </div>

                      {/* Step 3: Conditionally render 'addcitydiv' based on the state */}
                      {showCityDiv && (
                        <div className="addcitydiv">
                          <p className="addcitt">Your trip plan (Kolhapur-Pune)</p>
                          <div className="row">
                            <div className="col-md-4 mb-2 bd-right">
                              <p className="lable">Day 1 :</p>
                              <p className="data">Going from Pune to Kolhapur</p>
                            </div>
                            <div className="col-md-4 mb-2 bd-right">
                              <p className="lable">Leaving at :</p>
                              <p className="data">12.55pm on Aug 20, 2024</p>
                            </div>
                            <div className="col-md-4 mb-2">
                              <div className="d-flex">
                                <div>
                                  <p className="lable">Expected to reach at :</p>
                                  <p className="data">4.55pm on Aug 20, 2024</p>
                                </div>
                                <div className="">
                                  <FontAwesomeIcon icon={faTrash} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* Outstation multicity form end */}

                    <br></br> <br></br>

                    {/* Local hourly package daily rental form start */}
                    <div className="row">
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Pickup City</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Select length of time </Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Date Of Pickup</Form.Label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            name="deadline"
                            id="deadline"
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Time Of Pickup</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {/* Local hourly package daily rental form end */}


                    <br></br> <br></br>

                    {/* Local hourly package pick up from airport start  */}
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Which airport are we pickup from</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Going to location</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Date Of Pickup</Form.Label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            name="deadline"
                            id="deadline"
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Time Of Pickup</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {/* Local hourly package pick up from airport end  */}


                    <br></br> <br></br>


                    {/* Local hourly package drop off from airport start  */}
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Which airport are we dropping you at</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Pickup location</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Date Of Pickup</Form.Label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            name="deadline"
                            id="deadline"
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Time Of Pickup</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {/* Local hourly package drop off from airport end  */}


                    <br></br> <br></br>


                    {/* airport transfer drop-off from airport start  */}
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Which airport are we pickup from</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Goint to location</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Date Of Pickup</Form.Label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            name="deadline"
                            id="deadline"
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Time Of Pickup</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {/* airport transfer drop-off from airport end  */}


                    <br></br> <br></br>


                    {/* airport transfer pick-up from airport start  */}
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Which airport are we Dropping you at</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Pickup location</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Date Of Pickup</Form.Label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            name="deadline"
                            id="deadline"
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Time Of Pickup</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {/* airport transfer pick-up from airport end  */}


                    <br></br> <br></br>


                    {/* airport transfer drop off from airport (outstation) start  */}
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Which airport are you need the pickup from</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Going to location</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Date Of Pickup</Form.Label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            name="deadline"
                            id="deadline"
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Time Of Pickup</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {/* airport transfer drop off from airport (outstation) end  */}


                    <br></br> <br></br>


                    {/* airport transfer pick-up from airport (outstation) start  */}
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Pickup city</Form.Label>
                          <Select options={options} />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Which airport do you need the dropping at?</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Date Of Pickup</Form.Label>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            name="deadline"
                            id="deadline"
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <Form.Group className="formgroup">
                          <Form.Label>Time Of Pickup</Form.Label>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {/* airport transfer pick-up from airport (outstation) end  */}



                    <div className="nextbtn-div">
                      <button className="bookbtn" type="button" onClick={() => setModalShow(true)}><span>Next</span></button>
                    </div>

                    <div className="bd-form"></div>

                    <div className="form-instructions">
                      <div className="row">
                        <div className="col-md-10 mx-auto">
                          <ul>
                            <li>Chauffeur driven AC cab</li>
                            <li>Great choice when you need a cab for a few hours within the city for the budgeted distance (in km).</li>
                            <li>Additional time or distance is billed as per actuals.</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Enter_phone_modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}

export default Book_form
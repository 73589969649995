import React from 'react'

import './CommonLogin.css'
import { Form } from 'react-bootstrap'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import Password_reset_sucess from './animation/Password_reset_sucess'

const Password_reset_successfully = () => {
  return (
    <>
        <section className='main-class-common'>
            <div className='container'>
                <div className='row row-height'>
                    <div className='col-lg-4 my-auto'>
                        <div className='authentication-form'>                            
                            <div className='login-modalss'>
                                <div className='animdiv'>
                                    <Password_reset_sucess />
                                </div>
                                <h6 className='successmsg'>Password Reset Successfully!</h6>
                                <p className='subtext'>Easy Access, Safe Travels</p>
                                <p className='redtext'>Your Ride, Just a Click Away!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Password_reset_successfully
import React, { useState } from 'react';
import './CommonLogin.css'
import { Form } from 'react-bootstrap'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faEye } from '@fortawesome/free-regular-svg-icons'

import OtpInput from 'react-otp-input';

const Set_Otp = () => {
    const [otp, setOtp] = useState('');
    return (
        <>
            <section className='main-class-common'>
                <div className='container'>
                    <div className='row row-height'>
                        <div className='col-lg-4 my-auto'>
                            <div className='authentication-form'>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/login-signup/password.png'} className='login-user' />
                                    <p className='common-form-txttt mt-4'>OTP has been Sent to
                                        <br></br>
                                        the<span className='red-txtt'> <Link to="#" className='red-txtt'>Email / Mobile Number </Link></span></p>
                                </div>
                                <Form className='py-4'>
                                    <Form.Group className="form-group otpinp-group">
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={6}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </Form.Group>

                                    <div className='row'>
                                        <div className='col-6'>
                                        <Link to="/set-new-password"><button className='cancel-btn' type='button'>Verify</button></Link>
                                        </div>
                                    </div>

                                </Form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Set_Otp


import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './CancelRideLottieModal.css';
import CancelRideLottie from './CancelRideLottie';

const CancelRideLottieModal = (props) => {
    const { show, onHide, message, submessage } = props; // Destructuring props for easier access
    
    // Use useEffect to hide the modal after 3 seconds
    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                onHide(); // Call the onHide function to hide the modal
            }, 3000); // 3000 milliseconds = 3 seconds

            // Cleanup the timer when the component is unmounted or if show changes
            return () => clearTimeout(timer);
        }
    }, [show, onHide]);

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Save_Changes_Modal'
            >
                <Modal.Body>
                    <CancelRideLottie />
                    <p className='mainsg'>{message}</p> 
                    <p className='subp'>{submessage}</p>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CancelRideLottieModal;
import React from 'react'
import './NotesDisclaimer.css'
import { Container } from 'react-bootstrap'
import Request_callback_modal from '../../common-components/modals/request-callback/Request_callback_modal'

const NotesDisclaimer = () => {

    const [modalShow, setModalShow] = React.useState(false);

    return (
        <section className='main-package-details'>
            <Container>
                <div className='notes-disclaimer'>
                    <h5 className='title-notes mb-4'>Notes and Disclaimers:</h5>
                    <ol>
                        <li>Driver's night allowance applicable if the driver drives between 10 pm & 6 am (Rs 250 for sedan & SUV and Rs 300 for tempo traveler) </li>
                        <li>If Cab runs more than total KMs estimated for the package, then per KMs charges will be applicable</li>
                        <li>A/C will be switched off in hilly areas. </li>
                        <li>The trip is for a specified period of time (same day). It may not be possible to visit all the places in the given time. Its advised that the client specify the places to visit ahead of the tour so that the client's needs are incorporated into the day and any confusion or disagreements are avoided. If for any reason, the sightseeing places that are planned to be visited are either not open or busy or if the plan is constrained by time, the client and driver need to adjust the itinerary to fit it into the available time. No refund shall be offered if the client is unable to visit all the places mentioned in the itinerary due to unavailability of time or natural or unnatural circumstances affecting the trip.</li>
                        <li>If a client requires a vehicle with roof-top carrier additional charges of ₹150/- shall apply.</li>
                    </ol>
                    <div className='text-center'>
                        <button onClick={() => setModalShow(true)} type="button" className='call-us'>Call/ Email us to Book</button>
                    </div>
                </div>
            </Container>

            <Request_callback_modal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </section>
    )
}

export default NotesDisclaimer
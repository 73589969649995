import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import './terms_policy.css'
import Icon_cab from '../home/icon-cab/Icon_cab'

const Terms_conditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();
    return (
        <>
            <section className='Terms_conditions_sec'>
                <div className='container-fluid p-0'>
                    <div className='terms_bannerdiv'>
                        <img className='bannimg' src={process.env.PUBLIC_URL + '/assets/images/terms/terms.png'} />
                        <div className='overlayclr'></div>
                    </div>

                    <div className='container'>
                        <div className='terms-content'>
                            <div className='row'>
                                <div className='col-md-8 mx-auto'>
                                    <div className='bannertitlediv'>
                                        <p>Terms and Conditions</p>
                                    </div>
                                </div>
                            </div>

                            <p className='subtitlep'>By accessing or using our cab service website or mobile app (collectively called the "Platform"), you agree to comply with and be bound by these Terms and
                                Conditions. Please read them carefully before using our services.</p>

                            <ol>
                                <li>Acceptance of Terms
                                    <p>By using our Platform, you agree to be legally bound by these Terms and Conditions, as well as any updates or amendments we may introduce. If you do not agree, you should discontinue
                                        using our Platform immediately.
                                    </p>
                                </li>

                                <li>Services Provided

                                    <p>Icon Cabs offers cab booking services that allow users to request rides from third-party drivers. We act as an intermediary, connecting passengers and drivers, but we are not directly
                                        responsible for the transportation services provided by drivers.</p>
                                </li>

                                <li>User Eligibility

                                    <p>To use our Platform, you must:</p>

                                    <p>- Be at least 18 years old.</p>
                                    <p>-Provide accurate and up-to-date information during registration.</p>
                                    <p>-Agree to use our Platform for lawful purposes only.</p>
                                </li>

                                <li>User Accounts and Responsibilities

                                    <p>You are responsible for:</p>
                                    <p>Maintaining the confidentiality of your account credentials.</p>

                                    <p>-Any activity that occurs under your account.</p>
                                    <p>-Ensuring that the information you provide is current, accurate, and complete.</p>
                                    <p>-We reserve the right to terminate or suspend accounts that are found to be in violation of these Terms.</p>
                                </li>

                                <li>Booking and Cancellation Policies

                                    <p>- Booking Process: You can book rides through our Platform by specifying your pickup and drop-off locations. A fare estimate may be provided, but the final fare is determined at the end
                                        of the trip. Cancellation: You can cancel a ride request within a specified timeframe. Cancellation fees may apply depending on when the cancellation is made..</p>
                                    <p>- No-Show: If you fail to show up at the designated pickup location within a reasonable time, you may be charged a no-show fee..</p>

                                </li>

                                <li>Fares and Payments

                                    <p>Fare Calculation: Fares are calculated based on distance, time, and applicable rates. Additional charges may apply for tolls, wait times, or other surcharges.
                                    </p>
                                    <p>-Payment: Payment methods include credit/debit cards, e-wallets, and other options available on the Platform. You authorize us to charge your selected payment method for the total fare.
                                    </p>
                                    <p>-Receipts: You will receive an electronic receipt via email or in-app notification after the completion of each ride.
                                    </p>
                                </li>

                                <li>Driver and Passenger Conduct

                                    <p>    Driver Responsibilities: Drivers are expected to maintain a safe and professional environment during the trip. They must comply with all traffic laws and ensure that vehicles are in good condition.</p>
                                    <p>-Passenger Responsibilities: Passengers should behave respectfully towards drivers and fellow passengers. Any misconduct, including damage to the vehicle, may result in additional</p>
                                    <p>-charges or account termination. </p>
                                </li>


                                <li>Liability and Disclaimers

                                    <p> Third-Party Services: We are not liable for any direct or indirect damages arising from the services provided by third-party drivers. Our role is limited to facilitating the connection between
                                        drivers and passengers.
                                    </p>
                                    <p>No Warranty: We provide our Platform on an "as-is" and "as-available" basis. We do not guarantee the availability, reliability, or accuracy of our services.</p>
                                    <p>-Limitation of Liability: To the fullest extent permitted by law, our total liability to you for any claims arising from the use of our Platform is limited to the amount you paid for the service in
                                        question.
                                    </p>
                                </li>


                                <li>Termination and Suspension

                                    <p>We reserve the right to terminate or suspend your access to our Platform if you violate these Terms or engage in any behavior deemed harmful to our business or other users.</p>
                                </li>


                                <li>Intellectual Property

                                    <p>All content on our Platform, including text, graphics, logos, and software, is owned by [Company Name] or our licensors. You may not reproduce, distribute, or create derivative works from
                                        any of this content without our express permission.
                                    </p>
                                </li>

                                <li>Governing Law and Dispute Resolution

                                    <p>These Terms are governed by the laws of [Your Jurisdiction]. Any disputes arising from these Terms will be resolved through binding arbitration in [Your Jurisdiction], except where prohibited by law.</p>
                                </li>

                                <li>Amendments

                                    <p>We may update these Terms periodically. Any changes will be posted on our Platform and become effective upon posting. Continued use of our services after the changes take effect signifies your acceptance of the new Terms.</p>
                                </li>

                                <li>Contact Information

                                    <p>If you have any questions or concerns about these Terms and Conditions, please contact us at</p>
                                </li>


                            </ol>
                        </div>
                    </div>
                </div>

                <Icon_cab />
            </section>
        </>
    )
}

export default Terms_conditions
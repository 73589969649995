import React from 'react'
import './BlogInnerText.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faComments, faUser } from '@fortawesome/free-regular-svg-icons'
import { faArrowRightLong, faShare } from '@fortawesome/free-solid-svg-icons'
import { Card, Col, Container, Row } from 'react-bootstrap'

const BlogInnerText = () => {
    return (
        <section className='blog-page'>
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} >
                        <div>
                            <div className='main-card-class-blog'>
                                <div className='main-card-img'>
                                    <img variant="top" src={process.env.PUBLIC_URL + '/assets/images/blog-page/Mask Group 2.png'} className='card-img' />
                                    <div className='blk-overlay'></div>
                                    <p className='card-text-img'>Plan the Perfect Outstation Trip with Our Reliable Cab Services</p>
                                </div>
                                <div className='small-txttt-card mt-3'>
                                    <p className='card-sub-txtt'><FontAwesomeIcon icon={faUser} className='me-2' />Rekha Kasbe</p>
                                    <p className='card-sub-txtt ms-3'><FontAwesomeIcon icon={faCalendar} className='me-2' />12 Aug 2023</p>
                                    <p className='card-sub-txtt ms-3'><FontAwesomeIcon icon={faComments} className='me-2' />240</p>
                                </div>
                                <p className='card-sub-txtt'>Planning an outstation trip can be both exciting and stressful. Whether you’re heading out for a weekend getaway, a family function, or a business trip, a well-planned journey can make all the difference. At Icon Cabs we’re here to ensure that your outstation travel is comfortable, convenient, and hassle-free.</p>
                                <div>
                                    <p className='card-sub-txtt'>In this blog, we’ll explore the benefits of booking a cab for your outstation journey and share some tips to help you plan the perfect trip.</p>
                                    <h5 className='title-blog-inner mb-4'>Why Choose a Cab Service for Your Outstation Trip?</h5>
                                    <ul>
                                        <li>Comfort and Convenience:
                                            <p className='card-sub-txtt'>When it comes to outstation travel, comfort is key. With our cab service, you can sit back, relax, and enjoy the journey while a professional driver takes care of the road. Unlike public transportation, you don’t have to worry about crowded buses or trains. You’ll have the entire vehicle to yourself, along with the flexibility to make stops along the way.</p>
                                        </li>
                                        <li>Door-to-Door Service:
                                            <p className='card-sub-txtt'>When it comes to outstation travel, comfort is key. With our cab service, you can sit back, relax, and enjoy the journey while a professional driver takes care of the road. Unlike public transportation, you don’t have to worry about crowded buses or trains. You’ll have the entire vehicle to yourself, along with the flexibility to make stops along the way.</p>
                                        </li>
                                        <li>24/7 Availability:
                                            <p className='card-sub-txtt'>When it comes to outstation travel, comfort is key. With our cab service, you can sit back, relax, and enjoy the journey while a professional driver takes care of the road. Unlike public transportation, you don’t have to worry about crowded buses or trains. You’ll have the entire vehicle to yourself, along with the flexibility to make stops along the way.</p>
                                        </li>
                                        <li>Cost-Effective for Group Travel:
                                            <p className='card-sub-txtt'>raveling with a group? Booking a cab can be more economical than purchasing multiple bus or train tickets. You can split the fare among your fellow passengers, making it a budget friendly option.</p>
                                        </li>
                                        <li>Customizable Itinerary:
                                            <p className='card-sub-txtt'>Unlike rigid schedules of public transport, you have the freedom to customize your itinerary with a cab service. Want to take a detour to visit a scenic spot or make an unscheduled stop for a meal? No problem! Your trip can be as flexible as you want it to be</p>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h5 className='title-blog-inner mb-4'>Tips for Planning an Outstation Trip with a Cab Service</h5>
                                    <ul>
                                        <li>Book in Advance:
                                            <p className='card-sub-txtt'>When it comes to outstation travel, comfort is key. With our cab service, you can sit back, relax, and enjoy the journey while a professional driver takes care of the road. Unlike public transportation, you don’t have to worry about crowded buses or trains. You’ll have the entire vehicle to yourself, along with the flexibility to make stops along the way.</p>
                                        </li>
                                        <li>Choose the Right Vehicle:
                                            <p className='card-sub-txtt'>When it comes to outstation travel, comfort is key. With our cab service, you can sit back, relax, and enjoy the journey while a professional driver takes care of the road. Unlike public transportation, you don’t have to worry about crowded buses or trains. You’ll have the entire vehicle to yourself, along with the flexibility to make stops along the way.</p>
                                        </li>
                                        <li>Plan Your Route:
                                            <p className='card-sub-txtt'>When it comes to outstation travel, comfort is key. With our cab service, you can sit back, relax, and enjoy the journey while a professional driver takes care of the road. Unlike public transportation, you don’t have to worry about crowded buses or trains. You’ll have the entire vehicle to yourself, along with the flexibility to make stops along the way.</p>
                                        </li>
                                        <li>Pack Smart:
                                            <p className='card-sub-txtt'>raveling with a group? Booking a cab can be more economical than purchasing multiple bus or train tickets. You can split the fare among your fellow passengers, making it a budget friendly option.</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className='dashed-border'></div>
                                {/* Top Comment */}
                                <div>
                                    <h5 className='category-title mb-5'><span className='bordder-title'>To</span><span className='bordder-title'>p</span> Comment</h5>
                                    <div>
                                        <Row>
                                            <Col lg={1} md={1} sm={2} xs={2}>
                                                <FontAwesomeIcon icon={faUser} className='user-iconnnn' />
                                            </Col>
                                            <Col lg={11} md={11} sm={10} xs={10}>
                                                <h5 className='user-name'>Rakesh Mote</h5>
                                                <p className='card-sub-txtt'>Great tips for planning an outstation trip! I’ve used Icon Cabs] for a couple of weekend getaways, and the experience was fantastic. The drivers were professional, and the journey was super comfortable. I especially loved the flexibility to customize our route and make stops whenever needed. Looking forward to booking my next trip with you guys</p>
                                                <div className='comment-txttt'>
                                                    <p className='card-sub-txtt ms-3'><FontAwesomeIcon icon={faCalendar} className='me-2' />12 Aug 2023</p>
                                                    <p className='rpl-txtt'><FontAwesomeIcon icon={faShare} className='me-2' />Reply</p>
                                                </div>
                                            </Col>
                                            <div className='brown-border'></div>
                                        </Row>
                                    </div>
                                    <div className='mt-4'>
                                        <Row>
                                            <Col lg={1} md={1} sm={2} xs={2}>
                                                <FontAwesomeIcon icon={faUser} className='user-iconnnn' />
                                            </Col>
                                            <Col lg={11} md={11} sm={10} xs={10}>
                                                <h5 className='user-name'>Rakesh Mote</h5>
                                                <p className='card-sub-txtt'>Great tips for planning an outstation trip! I’ve used Icon Cabs] for a couple of weekend getaways, and the experience was fantastic. The drivers were professional, and the journey was super comfortable. I especially loved the flexibility to customize our route and make stops whenever needed. Looking forward to booking my next trip with you guys</p>
                                                <div className='comment-txttt'>
                                                    <p className='card-sub-txtt ms-3'><FontAwesomeIcon icon={faCalendar} className='me-2' />12 Aug 2023</p>
                                                    <p className='rpl-txtt'><FontAwesomeIcon icon={faShare} className='me-2' />Reply</p>
                                                </div>
                                            </Col>
                                            <div className='brown-border'></div>
                                        </Row>
                                    </div>
                                    <div className='mt-4'>
                                        <Row>
                                            <Col lg={1} md={1} sm={2} xs={2}>
                                                <FontAwesomeIcon icon={faUser} className='user-iconnnn' />
                                            </Col>
                                            <Col lg={11} md={11} sm={10} xs={10}>
                                                <h5 className='user-name'>Rakesh Mote</h5>
                                                <p className='card-sub-txtt'>Great tips for planning an outstation trip! I’ve used Icon Cabs] for a couple of weekend getaways, and the experience was fantastic. The drivers were professional, and the journey was super comfortable. I especially loved the flexibility to customize our route and make stops whenever needed. Looking forward to booking my next trip with you guys</p>
                                                <div className='comment-txttt'>
                                                    <p className='card-sub-txtt ms-3'><FontAwesomeIcon icon={faCalendar} className='me-2' />12 Aug 2023</p>
                                                    <p className='rpl-txtt'><FontAwesomeIcon icon={faShare} className='me-2' />Reply</p>
                                                </div>
                                            </Col>
                                            <div className='brown-border'></div>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default BlogInnerText
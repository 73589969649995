import React, { useEffect } from "react";
import "./Contactandpickup_modal.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Cancel_ride_modal from "../cancel-ride/Cancel_ride_modal";
import Confirm_Booking_modal from "../confirm_Booking_modal/Confirm_Booking_modal";
import Cab_Modal from "../cab_Modal/Cab_Modal";

const Contactandpickup_modal = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalConfirmShow, setConfirmModalShow] = React.useState(false);
  const [modalCabShow, setCabModalShow] = React.useState(false);

  // Effect to handle closing Confirm_Booking_modal and showing Cab_Modal after 3 seconds
  useEffect(() => {
    if (modalConfirmShow) {
      const timer = setTimeout(() => {
        setConfirmModalShow(false); // Close Confirm_Booking_modal
        setCabModalShow(true); // Show Cab_Modal
      }, 3000);

      // Cleanup the timer when modalConfirmShow changes or component unmounts
      return () => clearTimeout(timer);
    }
  }, [modalConfirmShow]);

  const handleAdvanceBook = () => {
    setModalShow(true);
    props.onHide();
  };

  const handleConfirmBook = () => {
    setConfirmModalShow(true);
    props.onHide();
  };
  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Contactandpickup_modal"
      >
        <div className="row">
          <div className="col-md-5 my-auto">
            <div className="sec1">
              <div className="titlediv">
                <p className="title">Booking Details</p>
              </div>
              <div className="contentdiv">
                <div className="row mb-2">
                  <div className="col-4">
                    <p className="ittt">Itinerary :</p>
                  </div>
                  <div className="col-8">
                    <p className="punee">
                      Pune <span> {">"} </span> LonavalaPickup
                    </p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <p className="ittt">Date :</p>
                  </div>
                  <div className="col-8">
                    <p className="punee">21st August 2024 </p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <p className="ittt">Time :</p>
                  </div>
                  <div className="col-8">
                    <p className="punee">7:00 AM</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <p className="ittt">Car Type :</p>
                  </div>
                  <div className="col-8">
                    <p className="punee">Wagon R or Equivalent</p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <p className="ittt">KMs Included :</p>
                  </div>
                  <div className="col-8">
                    <p className="punee">68 km</p>
                  </div>
                </div>

                <div className="bdbottom"></div>

                <div className="row mb-2">
                  <div className="col-4">
                    <p className="ittt">Total Fare :</p>
                  </div>
                  <div className="col-8">
                    <p className="punee">
                      <span>₹</span> 1707
                    </p>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">
                    <p className="ittt">Advance booking amount :</p>
                  </div>
                  <div className="col-8">
                    <p className="punee">
                      <span>₹</span> 100
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 adjustcol">
            <div className="sec2">
              <div className="hedsec">
                <div className="row">
                  <div className="col-6">
                    <h6>Contact And Pickup Details</h6>
                  </div>
                  <div className="col-6 bd-bot"></div>
                </div>
              </div>
              <div className="formsec">
                <Form>
                  <Form.Group className="form-group">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter Name" />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="text" placeholder="Enter Email" />
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                          inline
                          label="Send trip details to alternate email"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                        />
                      </div>
                    ))}
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Alternate Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Alternate Email"
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Pickup Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Pickup Address"
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Dropoff Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Dropoff Address"
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <div className="row">
                      <div className="col-6">
                        <button
                          className="cancel-btn btn"
                          type="button"
                          onClick={handleAdvanceBook}
                        >
                          Cancel Ride
                        </button>
                      </div>
                      <div className="col-6 ps-0">
                        <button
                          className="Proceed-btn btn"
                          type="button"
                          onClick={handleConfirmBook}
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Cancel_ride_modal show={modalShow} onHide={() => setModalShow(false)} />

      <Confirm_Booking_modal
        show={modalConfirmShow}
        onHide={() => setConfirmModalShow(false)}
      />

      <Cab_Modal show={modalCabShow} onHide={() => setCabModalShow(false)} />
    </>
  );
};

export default Contactandpickup_modal;

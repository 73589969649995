import React, { useEffect } from 'react';
import './Thank_you_booking.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Thankyou_success from './Animation/Thankyou_success';
import Meet_at_pickup_modal from '../meet-at-pickup/Meet_at_pickup_modal';

const Thank_you_booking = (props) => {
  const [modalShow, setModalShow] = React.useState(false);

  // Automatically close the modal after 3 seconds and open the next one
  useEffect(() => {
    if (props.show) {
      const timer = setTimeout(() => {
        setModalShow(true);  // Show Meet_at_pickup_modal
        props.onHide();      // Hide Thank_you_booking modal
      }, 3000); // 3000ms = 3 seconds

      return () => clearTimeout(timer); // Cleanup timer on component unmount or re-render
    }
  }, [props.show]);



  return (
    <>
      {/* Thank You Booking Modal */}
      <Modal
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='Thank_you_booking'
      >
        <Modal.Body>
          <div className='animation-div'>
            <Thankyou_success />
          </div>
          <div className='message-div'>
            <h6 className='main-msg'>Thank you! for Booking</h6>
            <p className='sub-msg'>Your Driver details and vehicle number will be shared shortly</p>
          </div>
        </Modal.Body>
      </Modal>

      {/* Meet at Pickup Modal */}
      <Meet_at_pickup_modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Thank_you_booking;

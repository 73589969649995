import React from 'react'
import './Abt_banner.css'

const Abt_banner = () => {
    return (
        <>
            <section className='Abt_banner_sec'>
                <div className='container-fluid p-0'>
                    <div className='bannr-div'>
                        <img className='abt-banner' src={process.env.PUBLIC_URL + '/assets/images/about/banner/banner.png'} />

                        <div className='whiteoverlay'></div>
                    </div>
                </div>
                <div className='overlay-content'>
                    <div className=' container'>
                        <div className='row'>
                            <div className='col-md-6 '>
                                <div className='vision-div'>
                                    <div className='titlediv'>
                                        <h6 className='vis-title'>Vision</h6>
                                    </div>
                                    <div className='contentdiv'>
                                        <p className='content'>To be the leading provider of dependable and high-quality cab services across
                                            India, transforming the way people travel by setting new standards for comfort,
                                            reliability, and customer satisfaction.</p>
                                        <div className='redbd'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mt-md-5'>
                                <div className='vision-div'>
                                    <div className='contentdiv'>
                                        <p className='content'>To be the leading provider of dependable and high-quality cab services across
                                            India, transforming the way people travel by setting new standards for comfort,
                                            reliability, and customer satisfaction.</p>
                                        <div className='redbd'></div>
                                    </div>
                                    <div className='titlediv'>
                                        <h6 className='mis-title'>Mission</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Abt_banner
import React, { useState } from "react";
import "./Confirm_Booking_modal.css";
import Modal from "react-bootstrap/Modal";
import Confirm_Thankyou_success from "./Animation/Confirm_Thankyou_success";

const  Confirm_Booking_modal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Thank_you_booking confirm_Booking_modal"
      >
        <Modal.Body>
          <div className="animation-div">
            <Confirm_Thankyou_success />
          </div>
          <div className="message-div">
            <h6 className="main-msg">Thank you! Your Booking is now Confirmed.</h6>
            <p className="sub-msg">
            You Also Receive One Mail On Your register Email Address regarding your booking
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Confirm_Booking_modal;

import React from 'react'
import './Where_is_cab.css'

const Where_is_cab = () => {
    return (
        <>
            <section className='Where_is_cab_sec'>
                <div className='container-fluid p-0'>
                    <div className='row ms-0 me-0 flexrotate'>

                        <div className='graph-div'>
                            <iframe
                                className="gmap_iframe"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                                src="https://maps.google.com/maps?width=600&amp;height=100&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                style={{ width: "100%", height: "100vh" }}
                            >

                            </iframe>
                        </div>

                        <div className='datadiv'>
                            <div className=''>
                                <div className='row'>
                                    <div className='col-xl-4 col-md-6  p-0 ms-auto '>
                                        <h1 className='wherecab'>Where is My Cab?</h1>
                                        <div className='whwrediv'>
                                            <div className='contentdiv'>
                                                <h6 className='passttt'>Passenger details</h6>

                                                <div className='row mb-1'>
                                                    <div className='col-5'><p className='text'>Name and surname</p></div>
                                                    <div className='col-7'><p className='text'>pratiksha</p></div>
                                                </div>
                                                <div className='row mb-1'>
                                                    <div className='col-5'><p className='text'>Mobile number</p></div>
                                                    <div className='col-7'><p className='text'>8317268809</p></div>
                                                </div>
                                                <div className='row mb-1'>
                                                    <div className='col-5'><p className='text'>Email address</p></div>
                                                    <div className='col-7'><p className='text'>pratiksha@profcyma.com</p></div>
                                                </div>

                                                <button className='tractbtn'>Call to driver</button>
                                            </div>

                                            <div className='contentdiv'>
                                                <h6 className='passttt'>Booking details</h6>

                                                <div className='row mb-1'>
                                                    <div className='col-5'><p className='text'>Itinerary</p></div>
                                                    <div className='col-7'><p className='text'>Pune <span> > </span> LonavalaPickup</p></div>
                                                </div>
                                                <div className='row mb-1'>
                                                    <div className='col-5'><p className='text'>Date</p></div>
                                                    <div className='col-7'><p className='text'>21st August 2024 </p></div>
                                                </div>
                                                <div className='row mb-1'>
                                                    <div className='col-5'><p className='text'>Time</p></div>
                                                    <div className='col-7'><p className='text'>7:00 AM</p></div>
                                                </div>
                                                <div className='row mb-1'>
                                                    <div className='col-5'><p className='text'>Car Type</p></div>
                                                    <div className='col-7'><p className='text'>Wagon R or Equivalent</p></div>
                                                </div>
                                                <div className='row mb-1'>
                                                    <div className='col-5'><p className='text'>KMs Included</p></div>
                                                    <div className='col-7'><p className='text'>68 km</p></div>
                                                </div>
                                                <div className='row mb-1'>
                                                    <div className='col-5'><p className='text'>Payment method</p></div>
                                                    <div className='col-7'><p className='text'>Online</p></div>
                                                </div>
                                            </div>


                                            <h6 className='passttt'>Total Fare :</h6>
                                            <h6 className='passttt'>₹ 1707 + Parking (if applicable) Toll & all other charges Included</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='bgdivv'></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Where_is_cab
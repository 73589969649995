import React from 'react'
import './Our_services_sec.css'

const Our_services = () => {
    return (
        <>
            <section className='Our_services_sec'>
                <div className='container'>
                    <div className='titlediv'>
                        <h4 className='title'>Our Services</h4>
                        <h6 className='subtitle'>Where Comfort Meets Convenience!</h6>
                    </div>
                    <div className='servicessec'>
                        <div className='row'>
                            <div className='col-md-4 mb-4'>
                                <div className='service-div'>
                                    <p className='cardtitle'>Outstation</p>
                                    <div className='img-div'>
                                        <img className='serviceimg' src={process.env.PUBLIC_URL + '/assets/images/home/our-services/service1.png'} />
                                    </div>
                                    <button className='bookbtn'><span>Book Your Ride</span></button>
                                </div>
                            </div>
                            <div className='col-md-4 mb-4'>
                                <div className='service-div'>
                                    <p className='cardtitle'>Local Hourly Package</p>
                                    <div className='img-div'>
                                        <img className='serviceimg' src={process.env.PUBLIC_URL + '/assets/images/home/our-services/service2.png'} />
                                    </div>
                                    <button className='bookbtn'><span>Book Your Ride</span></button>
                                </div>
                            </div>
                            <div className='col-md-4 mb-4'>
                                <div className='service-div'>
                                    <p className='cardtitle'>Airport Transfer</p>
                                    <div className='img-div'>
                                        <img className='serviceimg' src={process.env.PUBLIC_URL + '/assets/images/home/our-services/service3.png'} />
                                    </div>
                                    <button className='bookbtn'><span>Book Your Ride</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Our_services
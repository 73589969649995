import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import BlogInnerText from './bloginnertext/BlogInnerText'
import BlogComment from './blogcomment/BlogComment'

const BlogInner = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { pathname } = useLocation();
    
    return (
        <>
            <BlogInnerText />
            <BlogComment />
        </>
    )
}

export default BlogInner
import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import './BlogsPage.css'
import { Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faCalendar, faComments, faUser } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom'

const blogData = [
    {
        id: 1,
        title: 'Plan the Perfect Outstation Trip with Our Reliable Cab Services',
        author: 'Rekha Kasbe',
        date: '12 Aug 2023',
        comments: 240,
        image: '/assets/images/blog-page/1.png',
        description: 'Planning an outstation trip can be both exciting and stressful. Whether you’re heading out for a weekend getaway, a family function, or a business trip, a well-planned journey can make all the difference. At Icon Cabs we’re here to ensure that your outstation travel is comfortable, convenient, and hassle-free.',
    },
    {
        id: 2,
        title: 'Plan the Perfect Outstation Trip with Our Reliable Cab Services 23',
        author: 'Komal Nathe',
        date: '13 Aug 2024',
        comments: 256,
        image: '/assets/images/blog-page/2.png',
        description: 'Planning an outstation trip can be both exciting and stressful. Whether you’re heading out for a weekend getaway, a family function, or a business trip, a well-planned journey can make all the difference. At Icon Cabs we’re here to ensure that your outstation travel is comfortable, convenient, and hassle-free.',
    },
    {
        id: 3,
        title: 'Plan the Perfect Outstation Trip with Our Reliable Cab Services 23',
        author: 'Komal Nathe',
        date: '13 Aug 2024',
        comments: 256,
        image: '/assets/images/blog-page/3.png',
        description: 'Planning an outstation trip can be both exciting and stressful. Whether you’re heading out for a weekend getaway, a family function, or a business trip, a well-planned journey can make all the difference. At Icon Cabs we’re here to ensure that your outstation travel is comfortable, convenient, and hassle-free.',
    },
    {
        id: 4,
        title: 'Plan the Perfect Outstation Trip with Our Reliable Cab Services 23',
        author: 'Komal Nathe',
        date: '13 Aug 2024',
        comments: 256,
        image: '/assets/images/blog-page/4.png',
        description: 'Planning an outstation trip can be both exciting and stressful. Whether you’re heading out for a weekend getaway, a family function, or a business trip, a well-planned journey can make all the difference. At Icon Cabs we’re here to ensure that your outstation travel is comfortable, convenient, and hassle-free.',
    },

]

const BlogsPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();
    return (
        <section className='blog-page'>
            <Container>
                <Row>
                    <Col lg={9} md={8} sm={12} xs={12} >
                        <Row>
                            {blogData.map(blog => (
                                <Col key={blog.id} lg={6} md={12} sm={12} xs={12} >
                                    <div className='mt-4'>
                                        <Link to='/blogs-inner'>
                                            <Card className='main-card-class'>
                                                <div className='main-card-img'>
                                                    <Card.Img variant="top" src={process.env.PUBLIC_URL + blog.image} className='card-img' />
                                                    <div className='blk-overlay'></div>
                                                    <p className='card-text-img'>{blog.title}</p>
                                                </div>
                                                <Card.Body>
                                                    <div className='small-txttt-card'>
                                                        <div className='d-flex'>
                                                            <p className='card-sub-txtt'><FontAwesomeIcon icon={faUser} className='me-2' />{blog.author}</p>
                                                            <p className='card-sub-txtt ms-3'><FontAwesomeIcon icon={faCalendar} className='me-2' />{blog.date}</p>
                                                        </div>
                                                        <p className='card-sub-txtt'><FontAwesomeIcon icon={faComments} className='me-2' />{blog.comments}</p>
                                                    </div>
                                                    <p className='card-sub-txtt'>{blog.description}</p>
                                                    <div className='brown-border'></div>
                                                    <button className='read-more mt-2'>Read More<FontAwesomeIcon icon={faArrowRightLong} className='ms-2' /></button>
                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col lg={3} md={4} sm={12} xs={12} >
                        <InputGroup className="mb-3 mt-4">
                            <Form.Control
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Text id="basic-addon2"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                        </InputGroup>
                        <div className='light-pink-bggg'>
                            <h5 className='category-title'><span className='bordder-title'>Ca</span><span className='bordder-title'>t</span>egories</h5>
                            <div className='ms-4 mt-4'>
                                <p className='card-sub-txtt'>Airport Transport</p>
                                <p className='card-sub-txtt'>Business Transport</p>
                                <p className='card-sub-txtt'>World Tour</p>
                                <p className='card-sub-txtt'>Local Places</p>
                                <p className='card-sub-txtt'>Out Stations</p>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default BlogsPage

import React from 'react'
import './Package_Banner.css'

const Package_Banner = () => {
  return (
    <>
        <section className='Package_Banner_sec'>
            {/* <div className='container-fluid p-0'>
                <div className='banner-div'>
                    <img className='banner' src={process.env.PUBLIC_URL + '/assets/images/packages/ban2.png'} />

                    <div className='white-overlay'></div>
                </div>
            </div> */}
        </section>
    </>
  )
}

export default Package_Banner
import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <section className="footer-main">
        <div className="container">
          <div className="topfoot">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <div className="topfootbd">
                  <p className="poptext">Popular Rides</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid p-0">
          <div className="footer2">
            <div className="container">
              <div className="foot2in">
                <div className="row">
                  <div className="col-xl-2 colwidth">
                    <div className="menusdiv">
                      <h6 className="menutitle">Cab from Delhi</h6>
                      <div className="menus"><Link to="/popular-ride">Taxi from Delhi to Agra</Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Delhi to Jaipur
                      </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Delhi to Chandigarh
                      </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Delhi to Amritsar
                      </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Delhi to Manali</Link></div>
                    </div>
                  </div>

                  <div className="col-xl-2 colwidth">
                    <div className="menusdiv">
                      <h6 className="menutitle">Cab from Bangalore</h6>
                      <div className="menus"><Link to="/popular-ride">Taxi from Bangalore to Mysore</Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Bangalore to Coorg </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Bangalore to Ooty</Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Bangalore to Pondicherry</Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Bangalore to Goa</Link></div>
                    </div>
                  </div>

                  <div className="col-xl-2 colwidth">
                    <div className="menusdiv">
                      <h6 className="menutitle">Cab from Mumbai</h6>
                      <div className="menus"><Link to="/popular-ride">Taxi from Mumbai to Shirdi
                      </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Mumbai to Shani Shingnapur

                      </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Mumbai to Mahabaleshwar

                      </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Mumbai to Nashik

                      </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Mumbai to Pune</Link></div>
                    </div>
                  </div>

                  <div className="col-xl-2 colwidth">
                    <div className="menusdiv">
                      <h6 className="menutitle">Cab from Chennai</h6>
                      <div className="menus"><Link to="/popular-ride">Taxi from Chennai to Bangalore
                      </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Chennai to Pondicherry

                      </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Chennai to Tirupati

                      </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Chennai to Mahabalipuram

                      </Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Chennai to Kanchipuram</Link></div>
                    </div>
                  </div>

                  <div className="col-xl-2 colwidth">
                    <div className="menusdiv">
                      <h6 className="menutitle">Cab from Chandigarh</h6>
                      <div className="menus"><Link to="/popular-ride">Taxi from Chandigarh to Delhi</Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Chandigarh to Amritsar</Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Chandigarh to Manali</Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Chandigarh to Shimla</Link></div>
                      <div className="menus"><Link to="/popular-ride">Taxi from Chandigarh to Ludhiana</Link></div>
                    </div>
                  </div>


                </div>
              </div>
              <div className="foot3in">

                <div className="text-center pt-4 pb-5">
                  <p className="adddtextt"><img className="addicon" src={process.env.PUBLIC_URL + '/assets/images/icons/addicon.png'} />Explore More Cities</p>
                </div>

                <div className="footer3">
                  <div className="row">
                    <div className="col-lg-3 col-sm-12 col-12">
                      <div className="adddivvvv">
                        <img className="footlogo" src={process.env.PUBLIC_URL + '/assets/images/logo/logo.png'} />

                        <div className="d-flex mt-3">
                          <img className="addicnn" src={process.env.PUBLIC_URL + '/assets/images/icons/foot_add.png'} />
                          <p className="addtext">337 .old Ghtrd West Tambaram,
                            Chennai,Pune,600045,India</p>
                        </div>

                      </div>
                    </div>
                    <div className="col-lg-9 col-sm-9 col-12">
                      <div className="foot-menus">

                        <div className="row">
                          <div className="col-lg-2 col-sm-6 col-6 mb-4">
                            <div className="menusdiv">
                              <h6 className="divtti">Explore</h6>
                              <div className="menus"><Link to="/">Home</Link></div>
                              <div className="menus"><Link to="/blogs">Blog</Link></div>
                              <div className="menus"><Link to="/faq">Faq's</Link></div>
                              <div className="menus"><Link to="/aboutus">Why Icon Cabs?</Link></div>
                              <div className="menus"><Link to="/routes">Routes</Link></div>
                            </div>
                          </div>
                          <div className="col-lg-2  col-sm-6 col-6 mb-4">
                            <div className="menusdiv">
                              <h6 className="divtti">About Services</h6>
                              <div className="menus"><Link to={"/our-services"} onClick={() => { window.scrollTo(0, 0)}}>Outstation</Link></div>
                              <div className="menus"><Link to={"/our-services"} onClick={() => { window.scrollTo(0, 0)}}>Local Hourly Package</Link></div>
                              <div className="menus"><Link to={"/our-services"} onClick={() => { window.scrollTo(0, 0)}}> Airport-transfers</Link></div>
                              <div className="menus"><Link to={"/packages"}>Package</Link></div>
                            </div>
                          </div>
                          <div className="col-lg-2  col-sm-6 col-6 mb-4">
                            <div className="menusdiv">
                              <h6 className="divtti">Policies</h6>
                              <div className="menus"><Link to="/privacy-policy">Privacy Policy</Link></div>
                              <div className="menus"><Link to="/terms-conditions">Terms and conditions</Link></div>
                              <div className="menus"><Link to="/disclainer">Disclaimer</Link></div>
                            </div>
                          </div>
                          <div className="col-lg-3  col-sm-6 col-6 mb-4">
                            <div className="menusdiv">
                              <h6 className="divtti">Contact us</h6>
                              <div className="menus"><Link><img className="icon" src={process.env.PUBLIC_URL + '/assets/images/footer/icons/phone.png'} />+91 3445564333</Link></div>
                              <div className="menus"><Link><img className="icon" src={process.env.PUBLIC_URL + '/assets/images/footer/icons/mail.png'} />Iconcabs@gmail.com</Link></div>
                            </div>
                          </div>

                          <div className="col-lg-2  col-sm-6 mb-4">
                            <div className="menusdiv">
                              <h6 className="divtti">Follow Us</h6>

                              <div className="d-flex">
                                <div className="menus"><Link><img className="icon" src={process.env.PUBLIC_URL + '/assets/images/footer/icons/facebook.png'} /></Link></div>

                                <div className="menus"><Link><img className="icon" src={process.env.PUBLIC_URL + '/assets/images/footer/icons/instagram.png'} /></Link></div>

                                <div className="menus"><Link><img className="icon" src={process.env.PUBLIC_URL + '/assets/images/footer/icons/twitter.png'} /></Link></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8 mx-auto">
                  <div className="copyrightvdiv text-center">
                  
                    <p className="cpytext">
                      © 2024 All Rights Reserved Terms Of Use | Privacy Policy Powered by{" "}
                      <Link to="https://profcymaglobal.com/">Profcyma</Link>
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;

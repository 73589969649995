import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import Icon_cab from '../home/icon-cab/Icon_cab'
import Booking_breadcrumb from './booking-breadcrumb/Booking_breadcrumb'
import Book_form from './book-form/Book_form'

const Booking_form = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();
    return (
        <>
            <Booking_breadcrumb />
            <Book_form />
            <Icon_cab />
        </>
    )
}

export default Booking_form
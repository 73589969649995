import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import './Attach_taxipage.css'
import Form from 'react-bootstrap/Form';

import Booking_breadcrumb from '../booking-form/booking-breadcrumb/Booking_breadcrumb'
import Icon_cab from '../home/icon-cab/Icon_cab'
import Still_question from '../routesinner/Still_question'
import Save_Changes_Modal from "../common-components/modals/save_changes_modal/Save_Changes_Modal";

const Attach_taxi = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();

    const [modalShow, setModalShow] = React.useState(false);



    const advantages = [
        {
            icon: '/assets/images/home/attach-taxi/icons/target.png',
            content: 'Icon Cabs prioritizes meeting customer demand.',
        },
        {
            icon: '/assets/images/home/attach-taxi/icons/customer.png',
            content: 'Just deliver top-quality service.',
        },
        {
            icon: '/assets/images/home/attach-taxi/icons/increase.png',
            content: 'Stay busy year-round. Quality service leads to increased business.',
        },
        {
            icon: '/assets/images/home/attach-taxi/icons/feedback.png',
            content: 'Receive excellent reviews from satisfied customers.',
        },
        {
            icon: '/assets/images/home/attach-taxi/icons/money.png',
            content: 'Icon Cabs ensures timely payments to you.',
        },
        {
            icon: '/assets/images/home/attach-taxi/icons/delivery.png',
            content: 'Stay connected with Icon Cabs anytime, anywhere.',
        },

    ];
    return (
        <>
            <section className='Attach_taxi_sec'>
                <div className='container-fluid p-0'>
                    {/* <Booking_breadcrumb /> */}

                    <div className='attachformdiv'>
                        <img className='attchformbgimg' src={process.env.PUBLIC_URL + '/assets/images/home/attach-taxi/attchtxi.png'} />
                        <div className='overlay-bg'></div>
                        <div className='formtopp'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-lg-6 '>
                                        <Booking_breadcrumb />
                                    </div>
                                    <div className='col-lg-6 '>
                                        <div className='form-div'>
                                            <h6 className='attch6'>Attach your taxi for local rides</h6>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control type="email" placeholder="Enter Name" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email" placeholder="Enter Email" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Phone Number</Form.Label>
                                                        <Form.Control type="email" placeholder="Enter Phone Number" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-12'>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>What city do you do most business in</Form.Label>
                                                        <Form.Control type="email" placeholder="Enter City" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-12'>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Driver license number</Form.Label>
                                                        <Form.Control type="email" placeholder="Enter Driver license number" />
                                                    </Form.Group>
                                                </div>
                                                <div className='col-12'>
                                                    <Form.Group className="mb-0 mt-4" >
                                                        <button onClick={() => {setModalShow(true);}} className='finishbtn'><span>Finish</span></button>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='adavantages_sec'>
                        <div className='titlediv'>
                            <h4 className='add'>Advantages</h4>
                            <p className='subp'> of the Attached your taxi</p>
                        </div>

                        <div className='container'>
                            <div className='advan'>
                                <div className='row'>
                                    {advantages.map((data, index) => (
                                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-3'>
                                            <div className='adavnce-card'>
                                                <div className='imgdiv'>
                                                    {/* <img className='icon' src={`${process.env.PUBLIC_URL}${data.icon}`} /> */}
                                                    <img className='icon' src={data.icon} />
                                                </div>
                                                <p className='content'>{data.content}</p>
                                            </div>
                                        </div>
                                    ))}


                                </div>
                            </div>
                        </div>
                    </div>



                    <div className='container'>
                        <Still_question />
                    </div>

                    <Icon_cab />
                </div>
                <Save_Changes_Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                message="Your changes have been saved successfully" // Passing a custom message

            />
            </section>
        </>
    )
}

export default Attach_taxi
import React from 'react'
import './CommonLogin.css'
import { Form } from 'react-bootstrap'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import Login_successful_modal from '../common-components/modals/login-successful-modal/Login_successful_modal'


const Login = () => {

    const [modalShow, setModalShow] = React.useState(false);
    return (

        <section className='main-class-common'>
            <div className='container'>
                <div className='row row-height'>
                    <div className='col-lg-4 my-auto'>
                        <div className='authentication-form'>
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/images/login-signup/log_in.png'} className='login-user' />
                                <p className='common-form-txttt mt-4'>Don't have an Account?<span className='red-txtt'> <Link to="/signup" className='red-txtt'>Sign Up</Link></span></p>
                            </div>
                            <Form className='mt-3'>
                                <Form.Group className="form-group">
                                    <Form.Label>Enter Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter Email" />
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Enter Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter Password" />
                                    <div className='text-end mt-1'>
                                        <Link to="/reset-password"><span className='common-form-txttt '>Forgot Password?</span></Link>
                                    </div>
                                </Form.Group>
                                <button className='sign-in-btnnn' type="button" onClick={() => setModalShow(true)}><span>Sign In</span></button>
                            </Form>
                            <div className='mt-3'>
                                <p className='or-txtt'>or</p>
                                <div className='text-center'>
                                    <p className='common-form-txttt'><img className='googg-img' src={process.env.PUBLIC_URL + '/assets/images/icons/google.png'} />Sign In with Google</p>
                                </div>
                                <div className='text-center'>
                                    <Link to="/">
                                        <FontAwesomeIcon icon={faHouse} className='house-iconnn' />
                                        <p className='common-form-txttt'>Back To Home</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Login_successful_modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                message="Signin Successfully!" // Passing a custom message
                submessage ="Your Journy Begins Now"
            />
        </section>
    )
}

export default Login
import React from 'react'
import './CommonLogin.css'
import { Form } from 'react-bootstrap'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faEye } from '@fortawesome/free-regular-svg-icons'

const ResetPassword = () => {
    return (
        <section className='main-class-common'>
            <div className='container'>
                <div className='row row-height'>
                    <div className='col-lg-4 my-auto'>
                        <div className='authentication-form'>
                            <div>
                                <img src={process.env.PUBLIC_URL + '/assets/images/login-signup/reset-password.png'} className='login-user' />
                                <p className='common-form-txttt mt-4'>You will receive an email from us requesting <br></br>
                                    you to<span className='red-txtt'> <Link to="#" className='red-txtt'>Reset your password.</Link></span></p>
                            </div>
                            <Form className='mt-3'>
                                <Form.Group className="form-group">
                                    <Form.Label>Email / Mobile Number</Form.Label>
                                    <Form.Control type="text" placeholder="Email / Mobile Number" />
                                </Form.Group>

                                <Form.Group className="form-group mt-5">
                                    <div className='row'>
                                        <div className='col-6'>
                                            <Link to="/login"><button className='cancel-btn' type='button'>Cancel</button></Link>
                                        </div>
                                        <div className='col-6'>
                                            <Link to="/otp"><button className='submit-btn' type='button'>Submit</button></Link>
                                        </div>
                                    </div>
                                </Form.Group>

                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ResetPassword
import React, { useState } from 'react'
import PackageHistory from '../../bookinghistory/packagehistory/PackageHistory'
import { Col, Container, Row } from 'react-bootstrap';
import './PackageCards.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Package_Form from '../package-form/Package_Form';
import './PackageCards.css'

const PackageCards = () => {

    const [modalShow, setModalShow] = useState(false);
    // Package data array
    const packages = [
        {
            name: 'Delhi Himanchal 8 days package',
            imgSrc: process.env.PUBLIC_URL + '/assets/images/booking-story/img1.png'
        },
        {
            name: 'Delhi Himanchal 8 days package',
            imgSrc: process.env.PUBLIC_URL + '/assets/images/booking-story/img1.png'
        },
        {
            name: 'Delhi Himanchal 8 days package',
            imgSrc: process.env.PUBLIC_URL + '/assets/images/booking-story/img1.png'
        },
    ];

    return (
        <section className='package_cards_main_sec'>
            <Container>



                <div className='row mt-5'>
                    <div className='col-xxl-10 col-lg-11 mx-auto'>
                        <Package_Form />


                        <Row>
                            {packages.map((pkg, index) => (
                                <Col key={index} md={6} lg={6} className="mb-4">
                                    <div className='main-package-slide'>
                                        <div className='img-ourwork-class'>
                                            <img src={pkg.imgSrc} className='package-img' alt={pkg.name} />
                                        </div>
                                        <div className='black-main'>
                                            <div className='main-class'>
                                                <div className='text-center'>
                                                    <FontAwesomeIcon icon={faLocationDot} className='location-dot' />
                                                </div>
                                                <p className='location-namee'>{pkg.name}</p>
                                                <div className='white-sub-bggg'>
                                                    <div className='row'>
                                                        <div className='col-6 p-0'>
                                                            <Link to="/package-details">
                                                                <button className='show-txttt'>Show Details</button>
                                                            </Link>

                                                        </div>
                                                        <div className='col-6 ps-1 pe-0'>
                                                            <button className='email-us-btnn' >Call/ Email us to Book</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>

            </Container>
        </section>


    )
}

export default PackageCards

import React, { useEffect, useState } from 'react';
import './Meet_at_pickup_modal.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Contactandpickup_modal from '../contact-and-pickup/Contactandpickup_modal';

const Meet_at_pickup_modal = (props) => {
  const [modalShow, setModalShow] = useState(false);

  // Automatically close Meet_at_pickup_modal after 3 seconds and open Contactandpickup_modal
  useEffect(() => {
    if (props.show) {
      const timer = setTimeout(() => {
        setModalShow(true);  // Show Contactandpickup_modal
        props.onHide();      // Hide Meet_at_pickup_modal
      }, 3000); // 3000ms = 3 seconds

      return () => clearTimeout(timer); // Cleanup timer on component unmount or re-render
    }
  }, [props.show]);

 

  return (
    <>
      {/* Meet at Pickup Modal */}
      <Modal
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='Meet_at_pickup_modal'
      >
        <Modal.Body>
          <div className='firstsec'>
            <div className='row mb-3'>
              <div className='col-6'>
                <div className='imgdiv'>
                  <div className='user-img'>
                    <img className='img1' src={process.env.PUBLIC_URL + '/assets/images/users/user1.png'} alt='user' />
                  </div>
                  <div className='car-img'>
                    <img className='img2' src={process.env.PUBLIC_URL + '/assets/images/users/usercar.png'} alt='car' />
                  </div>
                </div>
              </div>
              <div className='col-6 text-end'>
                <div className='d-flex justify-content-end'>
                  <div>
                    <p className='meetpp'>Meet at Pickup</p>
                    <p className='meetpp'>Spot on</p>
                  </div>
                  <div>
                    <p className='minpp'>30 Min</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-4'>
                <h6 className='name'>Aditya Jagtap</h6>
              </div>
              <div className='col-8'>
                <h6 className='name'>.4<img className='star' src={process.env.PUBLIC_URL + '/assets/images/icons/star.png'} alt='star' /></h6>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-4'>
                <p className='numberp'>+91 2345667754</p>
              </div>
              <div className='col-8'>
                <p className='numberp'>Mh24 Aj 34455</p>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-4'>
                <p className='numberp'>Your Pin for Ride</p>
              </div>
              <div className='col-8'>
                <div className='d-flex'>
                  <p className='picode'>9</p>
                  <p className='picode'>9</p>
                  <p className='picode'>9</p>
                  <p className='picode'>9</p>
                  <p className='picode'>9</p>
                  <p className='picode'>9</p>
                </div>
              </div>
            </div>

            <div className='d-flex'>
              <Form.Control type="text" placeholder="Send Message" />
              <button className='btn callbtn'>
                <img className='callicon' src={process.env.PUBLIC_URL + '/assets/images/icons/call2.png'} alt='call' />
              </button>
            </div>
          </div>
        </Modal.Body>

      </Modal>

      {/* Contact and Pickup Modal */}
      <Contactandpickup_modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Meet_at_pickup_modal;

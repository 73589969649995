import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import Our_banner from './our-banner/Our_banner'
import Our_form_tabs from './our-form-tabs/Our_form_tabs'
import Icon_cab from '../home/icon-cab/Icon_cab'

const Our_services = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();
    return (
        <>
            <div className='Our_services_main_sec'>
                <Our_banner />
                <Our_form_tabs />
                <Icon_cab />
            </div>
        </>
    )
}

export default Our_services
import React from 'react'
import './Abt_meet_team.css'


const Abt_meet_team = () => {
    return (
        <>
            <section className='Abt_meet_team'>
                <div className='container'>
                    <div className='meet-title-div'>
                        <p className='meetti'><span>Meet</span> our team</p>
                    </div>

                    <div className='teamsec'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-4 col-sm-6 col-10 mx-auto mb-4'>
                                <div className='team-card'>
                                    <div className='img-div'>
                                        <img className='temimg' src={process.env.PUBLIC_URL + '/assets/images/about/team/man1.png'} />
                                    </div>

                                    <div className='namebg-div'>
                                        <div className='namediv'>
                                            <p className='name'>Mr. Rakesh Roy</p>
                                            <p className='position'>Founder & CEO</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-6 col-10 mx-auto mb-4'>
                                <div className='team-card'>
                                    <div className='img-div'>
                                        <img className='temimg' src={process.env.PUBLIC_URL + '/assets/images/about/team/man2.png'} />
                                    </div>

                                    <div className='namebg-div'>
                                        <div className='namediv'>
                                            <p className='name'>Mr. Rakesh Roy</p>
                                            <p className='position'>Founder & CEO</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-6 col-10 mx-auto mb-4'>
                                <div className='team-card'>
                                    <div className='img-div'>
                                        <img className='temimg' src={process.env.PUBLIC_URL + '/assets/images/about/team/man3.png'} />
                                    </div>

                                    <div className='namebg-div'>
                                        <div className='namediv'>
                                            <p className='name'>Mr. Rakesh Roy</p>
                                            <p className='position'>Founder & CEO</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-6 col-10 mx-auto mb-4'>
                                <div className='team-card'>
                                    <div className='img-div'>
                                        <img className='temimg' src={process.env.PUBLIC_URL + '/assets/images/about/team/man1.png'} />
                                    </div>

                                    <div className='namebg-div'>
                                        <div className='namediv'>
                                            <p className='name'>Mr. Rakesh Roy</p>
                                            <p className='position'>Founder & CEO</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-8 mx-auto'>
                            <div className='bd-line-sec'></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Abt_meet_team
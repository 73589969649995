import React from 'react'
import './Our_form_tabs.css'

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';


const Our_form_tabs = () => {
    return (
        <>
            <section className='Our_form_tabs_sec'>
                <div className='container'>
                    <div className='tabsec'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="Outstation">
                            <Nav variant="pills" className="">
                                <Nav.Item>
                                    <Nav.Link eventKey="Outstation">Outstation</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Hourly">Local Hourly Package</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Airport">Airport Transfer</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content className='tabdatasection'>
                                <Tab.Pane eventKey="Outstation">
                                    <div className='row '>
                                        <div className='col-lg-8 mx-auto'>
                                            <div className='tabdata'>
                                                <div className='row'>
                                                    <div className='col-md-4 col-6 mb-4'>
                                                        <div className='form-box'>
                                                            <div className='img-div'>
                                                                <p className='formname'>One Way</p>
                                                                <div className='icondiv'>
                                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/our-services/tab-form/oneway.png'} />
                                                                </div>
                                                            </div>
                                                            <Link to="/booking-form"><button className='bookbbtn'><span>BooK Your Ride</span></button></Link>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-6 mb-4'>
                                                        <div className='form-box'>
                                                            <div className='img-div'>
                                                                <p className='formname'>Round Trip</p>
                                                                <div className='icondiv'>
                                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/our-services/tab-form/roundtrip.png'} />
                                                                </div>
                                                            </div>
                                                            <Link to="/booking-form"><button className='bookbbtn'><span>Round Trip</span></button></Link>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-6 mb-4'>
                                                        <div className='form-box'>
                                                            <div className='img-div'>
                                                                <p className='formname'>Round Trip</p>
                                                                <div className='icondiv'>
                                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/our-services/tab-form/multicity.png'} />
                                                                </div>
                                                            </div>
                                                            <Link to="/booking-form"><button className='bookbbtn'><span>Multicity</span></button></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="Hourly">
                                    <div className='row '>
                                        <div className='col-lg-8 mx-auto'>
                                            <div className='tabdata'>
                                                <div className='row'>
                                                    <div className='col-md-4 col-6 mb-4'>
                                                        <div className='form-box'>
                                                            <div className='img-div'>
                                                                <p className='formname'>Daily rental On
                                                                    hourly basis</p>
                                                                <div className='icondiv'>
                                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/our-services/tab-form/daily-rental.png'} />
                                                                </div>
                                                            </div>
                                                            <Link to="/booking-form"><button className='bookbbtn'><span>BooK Your Ride</span></button></Link>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-6 mb-4'>
                                                        <div className='form-box'>
                                                            <div className='img-div'>
                                                                <p className='formname'>Pick-up from
                                                                    Airport</p>
                                                                <div className='icondiv'>
                                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/our-services/tab-form/pickupfrom-airport.png'} />
                                                                </div>
                                                            </div>
                                                            <Link to="/booking-form"><button className='bookbbtn'><span>Round Trip</span></button></Link>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-6 mb-4'>
                                                        <div className='form-box'>
                                                            <div className='img-div'>
                                                                <p className='formname'>Drop-Off from
                                                                    Airport</p>
                                                                <div className='icondiv'>
                                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/our-services/tab-form/dropoff-from-airport.png'} />
                                                                </div>
                                                            </div>
                                                            <Link to="/booking-form"><button className='bookbbtn'><span>Multicity</span></button></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>


                                <Tab.Pane eventKey="Airport">
                                    <div className='row '>
                                        <div className='col-lg-10 mx-auto'>
                                            <div className='tabdata'>
                                                <div className='row'>
                                                    <div className='col-lg-3 col-md-4 col-6 mb-4'>
                                                        <div className='form-box'>
                                                            <div className='img-div'>
                                                                <p className='formname'>Drop-Off from
                                                                    Airport(Local)</p>
                                                                <div className='icondiv'>
                                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/our-services/tab-form/pickupfrom-airport.png'} />
                                                                </div>
                                                            </div>
                                                            <Link to="/booking-form"><button className='bookbbtn'><span>BooK Your Ride</span></button></Link>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3 col-md-4 col-6 mb-4'>
                                                        <div className='form-box'>
                                                            <div className='img-div'>
                                                                <p className='formname'>Pick-up from
                                                                    Airport(Local)</p>
                                                                <div className='icondiv'>
                                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/our-services/tab-form/dropoff-from-airport.png'} />
                                                                </div>
                                                            </div>
                                                            <Link to="/booking-form"><button className='bookbbtn'><span>Round Trip</span></button></Link>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3 col-md-4 col-6 mb-4'>
                                                        <div className='form-box'>
                                                            <div className='img-div'>
                                                                <p className='formname'>Drop-Off from
                                                                    Airport(Outstation)</p>
                                                                <div className='icondiv'>
                                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/our-services/tab-form/Drop-Off-from-Airport-Outstation.png'} />
                                                                </div>
                                                            </div>
                                                            <Link to="/booking-form"><button className='bookbbtn'><span>Multicity</span></button></Link>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3 col-md-4 col-6 mb-4'>
                                                        <div className='form-box'>
                                                            <div className='img-div'>
                                                                <p className='formname'>Pick-up from
                                                                    Airport(Outstation)</p>
                                                                <div className='icondiv'>
                                                                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/our-services/tab-form/pickup-from-Airport-Outstation.png'} />
                                                                </div>
                                                            </div>
                                                            <Link to="/booking-form"><button className='bookbbtn'><span>Multicity</span></button></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>

                            </Tab.Content>

                        </Tab.Container>
                    </div>


                </div>
            </section>


            <section className='dicover-package-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 mx-auto'>
                            <div className='dicoverdiv'>
                                <div className='row'>
                                    <div className='col-md-6 my-auto'>
                                        <div className='sec1'>
                                            <h5 className='dish6'>Discover</h5>
                                            <p className='our-p'>Our Travel Packages</p>
                                            <Link to={"/packages"}><button className='bookbbtn'><span>Explore Our Packages</span></button></Link>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='sec2'>
                                            <img className='img' src={process.env.PUBLIC_URL + '/assets/images/our-services/tab-form/Icon-cabs.png'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Our_form_tabs
import React from 'react'
import { Container } from 'react-bootstrap'
import './CabFare.css'

const CabFare = () => {
    return (
        <section className='cab-fare'>
            <div className='container-fluid p-0'>
                <Container className='content'>
                    <h3 className='title'>Delhi to Agra Cab Fare</h3>

                    <p className='description'>
                        Icon Cab is committed to keep the pricing as simple and clear as possible. So, we provide one price for your Delhi to Agra taxi which covers a specified maximum kilometer and maximum days. So, you know upfront how much taxi is going to cost you and plan your budget well. The prices are dynamic and transparent and give a win-win deal for both you and our partnered taxi services providers. CabBazar provides the best price for Delhi to Agra cab and our prices are generally 20% lower than competition.
                    </p>

                    <div className='table-content'>
                        <h3 className='table-title'>Delhi to Agra Cab Fare</h3>

                        <div className='row justify-content-center'> {/* Center the row */}
                            <div className='col-lg-10'>
                                <div className="table-responsive"> {/* Make table responsive */}
                                    <table className='table table-bordered text-center mx-auto'>
                                        <thead>
                                            <tr>
                                                <th>Vehicle Type</th>
                                                <th>Vehicle Type</th>
                                                <th>Vehicle Type</th>
                                                <th>Vehicle Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                            </tr>
                                            <tr>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                            </tr>
                                            <tr>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                            </tr>
                                            <tr>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                                <td>Hatchback</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>



                        </div>


                </Container>
            </div>
        </section>
    )
}

export default CabFare

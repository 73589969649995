import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import './terms_policy.css'
import Icon_cab from '../home/icon-cab/Icon_cab'
import Still_question from '../routesinner/Still_question'


const Faq = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();
    return (
        <>
            <section className='Terms_conditions_sec'>
                <div className='container-fluid p-0'>
                    <div className='terms_bannerdiv'>
                        <img className='bannimg' src={process.env.PUBLIC_URL + '/assets/images/terms/faq.png'} />
                        <div className='overlayclr'></div>
                    </div>

                    <div className='container'>
                        <div className='terms-content '>
                            <div className='row'>
                                <div className='col-md-8 mx-auto'>
                                    <div className='bannertitlediv'>
                                        <p>FAQ</p>
                                    </div>
                                </div>
                            </div>

                            <div className='faqlist'>
                                <div className='list'>
                                    <h6 className='tttt'>How do I book a cab?</h6>
                                    <p>Answer: <span>You can book a cab through our website or mobile app. Simply enter your travel details, select your preferred service, and confirm your booking.</span></p>
                                </div>
                                <div className='list'>
                                    <h6 className='tttt'>How do I book a cab?</h6>
                                    <p>Answer: <span>You can book a cab through our website or mobile app. Simply enter your travel details, select your preferred service, and confirm your booking.</span></p>
                                </div>
                                <div className='list'>
                                    <h6 className='tttt'>How do I book a cab?</h6>
                                    <p>Answer: <span>You can book a cab through our website or mobile app. Simply enter your travel details, select your preferred service, and confirm your booking.ou can book a cab through our website or mobile app. Simply enter your travel details, select your preferred service, and confirm your booking.ou can book a cab through our website or mobile app. Simply enter your travel details, select your preferred service, and confirm your booking.
                                    </span></p>
                                </div>
                                <div className='list'>
                                    <h6 className='tttt'>How do I book a cab?</h6>
                                    <p>Answer: <span>You can book a cab through our website or mobile app. Simply enter your travel details, select youou can book a cab through our website or mobile app. Simply enter your travel details, select your preferred service, and confirm your booking.r preferred service, and confirm your booking.</span></p>
                                </div>
                                <div className='list'>
                                    <h6 className='tttt'>How do I book a cab?</h6>
                                    <p>Answer: <span>You can book a cab through our website or mobile app. Simply enter your travel details, select your pou can book a cab through our website or mobile app. Simply enter your travel details, select your preferred service, and confirm your booking.ou can book a cab through our website or mobile app. Simply enter your travel details, select your preferred service, and confirm your booking.ou can book a cab through our website or mobile app. Simply enter your travel details, select your preferred service, and confirm your booking.referred service, and confirm your booking.</span></p>
                                </div>
                            </div>


                        </div>

                        <div className='mt-5'>
                            < Still_question/>
                        </div>

                    </div>


                </div>

                <Icon_cab />
            </section>
        </>
    )
}

export default Faq
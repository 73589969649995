import React from 'react'
import './our-banner.css'

const Our_banner = () => {
  return (
    <>
        <section className='Our_banner_sec'>
            <div className='container-fluid p-0'>
                <div className='banner-div'>
                    <img className='banner' src={process.env.PUBLIC_URL + '/assets/images/our-services/banner/banner.png'} />

                    <div className='white-overlay'></div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Our_banner
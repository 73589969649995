import React from 'react'
import './BlogComment.css'
import { Col, Container, Form, Row } from 'react-bootstrap'

const BlogComment = () => {
    return (
        <section className='post-comment'>
            <Container>
                <div className='post-comment-container'>
                    <div className='text-center'>
                        <h5 className='mb-5'>Post Comment</h5>
                    </div>
                    <Form>
                        <Row>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12} >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} xs={12} >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" />
                                </Form.Group>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Comment</Form.Label>
                                    <Form.Control as="textarea" rows={5} placeholder='Write From Here' />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className='post-btn-div'>
                            <div className='col-md-4 text-center'>
                                <button className='postbtn'>
                                    <span>Post Comment</span>
                                </button>
                            </div>
                        </div>

                    </Form>
                </div>
            </Container>
        </section>
    )
}

export default BlogComment
import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import './Account_setting.css'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';

const Edit_account_setting = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();
    return (
        <>
            <section className='Account_setting_sec'>
                <div className='container-fluid p-0'>
                    <div className='banner-div'>
                        <img className='banner-img' src={process.env.PUBLIC_URL + '/assets/images/booking-story/history-banner.png'} />
                    </div>


                    <div className='container'>
                        <div className='form-sec'>
                            <div className='row'>
                                <div className='col-lg-9 mx-auto'>
                                    <div className='form-box'>
                                        <div className='titlediv'>
                                            <h6 className='acctit'>Account settings</h6>

                                            <Link to="#" ><img className='edit-icon' src={process.env.PUBLIC_URL + '/assets/images/icons/edit-form.png'} /></Link>
                                        </div>

                                        <div className='row'>
                                            <div className='col-lg-9 mx-auto'>
                                                <Form>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <Form.Group className='form-group'>
                                                                <Form.Label>Name</Form.Label>
                                                                <Form.Control type="text" placeholder="Aditya" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Form.Group className='form-group'>
                                                                <Form.Label>Email</Form.Label>
                                                                <Form.Control type="text" placeholder="Aditya@gmail.com" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Form.Group className='form-group'>
                                                                <Form.Label>Phone Number</Form.Label>
                                                                <Form.Control type="text" placeholder="5678990054" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-12'>
                                                            <Form.Group className='form-group'>
                                                                <Form.Label>Change Password</Form.Label>
                                                                <Form.Control type="text" placeholder="5678990054" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-12 mt-4'>
                                                            <Form.Group className='form-group'>
                                                                <button className='bookbbtn'><span>Save Changes</span></button>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Edit_account_setting
import React from 'react'
import './Car_total_modal.css'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Thank_you_booking from '../thank-you-booking-modal/Thank_you_booking';

const Car_total_modal = (props) => {

    const [modalShow, setModalShow] = React.useState(false);

    const handleAdvanceBook = () => {
        setModalShow(true);   // Show Thank_you_booking modal
        props.onHide();       // Hide Car_total_modal
    }

    return (
        <>
            <Modal
                {...props}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Car_total_modal'
            >

                <Modal.Body>
                    <p className='vihiclename'>Indica</p>
                    <div className='img-div'>
                        <img className='carimg' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/car1.png'} />
                    </div>

                    <div className='totaldiv'>
                        <div className='row'>
                            <div className='col-5'>
                                <p className='tot-amt'>Total Amount:</p>
                            </div>
                            <div className='col-7'>
                                <div className='d-flex'>
                                    <p className='oldprice'>₹. <span>700</span></p>
                                    <p className='oriprice'>₹. <span>594</span></p>
                                </div>
                                <div className='d-flex'>
                                    <p className='smallprce'>₹. <span>494+</span></p>
                                    <p className='smallprce'><img className='rupeeicn' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/rupee.png'} /> ₹. <span>100</span></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className='freepp'>Free Charges:</p>

                    <div className='tab_sec'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="Included">

                            <Nav variant="pills" className="">
                                <Nav.Item>
                                    <Nav.Link eventKey="Included">Included</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="Excluded">Excluded</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="Included">
                                    <ul>
                                        <li>lorem ipsum dummy content</li>
                                        <li>lorem ipsum dummy content</li>
                                        <li>lorem ipsum dummy content</li>
                                        <li>lorem ipsum dummy content</li>
                                        <li>lorem ipsum dummy content</li>
                                    </ul>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Excluded">
                                    <ul>
                                        <li>lorem ipsum dummy content</li>
                                        <li>lorem ipsum dummy content</li>
                                        <li>lorem ipsum dummy content</li>
                                        <li>lorem ipsum dummy content</li>
                                        <li>lorem ipsum dummy content</li>
                                    </ul>
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>
                    </div>

                    <div className='paybtndiv'>
                        <button className='paybtn' type="button" onClick={handleAdvanceBook}>Pay <span>₹ 100</span> Advance and book</button>
                    </div>
                </Modal.Body>

            </Modal>

            <Thank_you_booking
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Car_total_modal
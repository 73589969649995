import React from 'react'
import './Why_ride.css'

const Why_ride = () => {
  return (
    <>
      <section className='Why_ride_sec'>
        <div className='topdiv'>
          <p className='whyride'>Why Ride With</p>
          <h2 className='iconcab'>Icon Cab?</h2>

          <div className='servicessec'>
            <div className='row mx-auto justify-content-center'>
              <div className='col-xl-2 col-md-3 col-sm-4 col-6 mb-5'>
                <div className='servicediv'>
                  <div className='icondiv'>
                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/home/why-ride/protection.png'} />
                  </div>
                  <h6 className='cardtitle'>Safety Features</h6>
                  <p className='content'>Share your ride details with friends or family for added security</p>
                </div>
              </div>
              <div className='col-xl-2 col-md-3 col-sm-4 col-6 mb-5'>
                <div className='servicediv'>
                  <div className='icondiv'>
                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/home/why-ride/24-hours.png'} />
                  </div>
                  <h6 className='cardtitle'>24/7 Availability</h6>
                  <p className='content'>Book a cab at any time, day or night, without worrying about availability</p>
                </div>
              </div>
              <div className='col-xl-2 col-md-3 col-sm-4 col-6 mb-5'>
                <div className='servicediv'>
                  <div className='icondiv'>
                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/home/why-ride/track.png'} />
                  </div>
                  <h6 className='cardtitle'>Real-Time Tracking
                  </h6>
                  <p className='content'>Easily track your cab in real-time, knowing exactly when it will arrive</p>
                </div>
              </div>
              <div className='col-xl-2 col-md-3 col-sm-4 col-6 mb-5'>
                <div className='servicediv'>
                  <div className='icondiv'>
                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/home/why-ride/offer.png'} />
                  </div>
                  <h6 className='cardtitle'>Promotions & Discounts</h6>
                  <p className='content'>Take advantage of online
                    exclusive deals and
                    discounts</p>
                </div>
              </div>
              <div className='col-xl-2 col-md-3 col-sm-4 col-6 mb-5'>
                <div className='servicediv'>
                  <div className='icondiv'>
                    <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/home/why-ride/transaction-history.png'} />
                  </div>
                  <h6 className='cardtitle'>Booking History
                  </h6>
                  <p className='content'>Access your past rides and easily rebook your favorite routes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Why_ride
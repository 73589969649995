import React from 'react'
import './Login_successful_modal.css'

import Lottie from 'react-lottie';
import * as animationData from '../../../common-components/animation/Success.json';

const Succefull_json = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <section className='pass_rest_success'>
                <div className='vdo-btn'>
                    <Lottie className="me-auto"
                        options={defaultOptions}
                    />
                </div>
            </section>
        </>
    )
}

export default Succefull_json
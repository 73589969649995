import React from 'react'
import { Container } from 'react-bootstrap'
import './CabRoutes.css'
import { Link } from 'react-router-dom'
const CabRoutes = () => {
    return (
        <section className='cab-routes'>
            <div className='container-fluid p-0'>
                <Container className='content'>
                    <h3 className='title'>Top Cab Routes from Delhi</h3>

                    <div className='row justify-content-center'> {/* Center the row */}
                        <div className='col-lg-10'>

                            <div className='row'> {/* Center the row */}
                                <div className='col-lg-6 col-md-6'>
                                    <div className='card1'>
                                        <label>Taxi From Delhi to agra</label>
                                    </div>
                                    <div className='card2'>
                                        <label>Distance: 230 km</label><br />
                                        <label>Estimated Time: 4 Hours</label><br />

                                        <div className='inner-div'>
                                            <h7 className='inner-div-title'>Tourist Attraction</h7>
                                            <div className='text'>
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='card1'>
                                        <label>Taxi From Delhi to agra</label>
                                    </div>
                                    <div className='card2'>
                                        <label>Distance: 230 km</label><br />
                                        <label>Estimated Time: 4 Hours</label><br />

                                        <div className='inner-div'>
                                            <h7 className='inner-div-title'>Tourist Attraction</h7>
                                            <div className='text'>
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                                <label>- Taj Mahal</label><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row justify-content-center'> {/* Center the row */}
                                <div className='col-lg-10 d-flex justify-content-center'> {/* Use flexbox to center the button */}
                                    <Link to={"/contact"} className='bookbtn'>Book a Cab</Link>
                                </div>
                            </div>


                        </div>
                    </div>

                </Container>



            </div></section>
    )
}

export default CabRoutes

import React from 'react'
import './Enter_phone_modal.css'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

const Enter_phone_modal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                className='Enter_phone_modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Enter Phone Number
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="formgroup">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" name="" placeholder='Enter Phone No' />
                        </Form.Group>
                        <Form.Group className="formgroup">
                            <Link to="/select-ride"><button className='submit-btn'>Submit</button></Link>
                        </Form.Group>
                    </Form>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Enter_phone_modal
import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import './terms_policy.css'
import Icon_cab from '../home/icon-cab/Icon_cab'


const Disclaimer = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { pathname } = useLocation();
    return (
        <>
            <section className='Terms_conditions_sec'>
                <div className='container-fluid p-0'>
                    <div className='terms_bannerdiv'>
                        <img className='bannimg' src={process.env.PUBLIC_URL + '/assets/images/terms/dis.png'} />
                        <div className='overlayclr'></div>
                    </div>

                    <div className='container'>
                        <div className='terms-content'>
                            <div className='row'>
                                <div className='col-md-8 mx-auto'>
                                    <div className='bannertitlediv'>
                                        <p>Disclaimer</p>
                                    </div>
                                </div>
                            </div>

                            <p className='subtitlep'>The information provided by [Company Name] on our website and through our services is intended for general informational purposes only. By using our website and
                                services, you agree to the terms of this disclaimer</p>

                            <ol>
                                <li>Information We Collect
                                    <p>We collect the following types of information:</p>
                                    <p>-Personal Information: This includes your name, email address, phone number, and payment details when you sign up, book a ride, or contact us.</p>
                                    <p>-Ride Information: Data related to your trips, such as pickup and drop-off locations, trip history, and ride preferences.</p>
                                    <p>- Location Data: Real-time location data (GPS) while using our app to provide location-based services.
                                    </p>
                                    <p>-Device Information: IP address, browser type, and other device-related details for analytics and improving our services.</p>
                                    <p>-Cookies and Tracking Data: Information collected through cookies and similar technologies to personalize and improve your experience.</p>
                                </li>

                                <li>How We Use Your Information

                                    <p>We use your information to:</p>
                                    <p>-Provide, maintain, and improve our services.</p>
                                    <p>-Process your ride requests and manage payments</p>
                                    <p>-Communicate with you regarding updates, promotions, and support.</p>
                                    <p>-Ensure the safety and security of our users, including fraud detection and prevention.</p>
                                    <p>-Analyze usage trends to enhance user experience.</p>
                                </li>

                                <li>Data Security

                                    <p>We take reasonable measures to protect your information from unauthorized access, alteration, or destruction. However, no method of transmission over the internet is completely secure.
                                    </p>
                                </li>

                                <li> Your Rights and Choices

                                    <p>Depending on your jurisdiction, you may have the following rights:</p>
                                    <p>Access and update your personal information.</p>
                                    <p>-Opt-out of marketing communications.</p>
                                    <p>-Restrict or object to certain processing activities</p>
                                    <p>-To exercise these rights, please get in touch with us</p>
                                </li>

                                <li> Third-Party Links
                                    <p>Our website may contain links to third-party sites. We are not responsible for the privacy practices of these sites. Please review their privacy policies.
                                    </p>
                                </li>

                                <li> Changes to This Policy
                                    <p>We may update this Privacy Policy periodically. We will notify you of any significant changes through email or our website. The updated policy will be effective upon posting</p>
                                </li>



                            </ol>
                        </div>
                    </div>
                </div>

                <Icon_cab />
            </section>
        </>
    )
}

export default Disclaimer
import React from 'react'

import { Col, Container, Nav, Row, Tab, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Still_question = () => {
    return (
        <>
            <div className='red-bggg'>
                <Row>
                    <Col lg={7} md={7} sm={12} xs={12} >
                        <div className=''>
                            <h4 className='white-title-texttt '>Still Have Questions? <span>We’re Here to Help!</span></h4>
                            <Link to="/contact"><button className='contactbtn '><span>Contact Us</span></button></Link>
                        </div>
                    </Col>
                    <Col lg={5} md={5} sm={12} xs={12} >
                        <img src='/assets/images/blog-page/red-torn-paper-with-question-mark-white-background-question-mark-concept.png' className='red-imgg' />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Still_question
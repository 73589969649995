import React from 'react'
import './PackageDetailsText.css'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import Booking_breadcrumb from '../../booking-form/booking-breadcrumb/Booking_breadcrumb'

const PackageDetailsText = () => {
    return (
        <section className='main-package-details packagetoopp'>
            <div className='pachangetopbann'>
                <Booking_breadcrumb />

                <div className='bannerdivv'>
                    <img className='banner' src={process.env.PUBLIC_URL + '/assets/images/packages/banner.png'} />
                    <div className='overlaybgg'></div>
                </div>
            </div>

            

            <Container>
                <div className='locationamediv'>
                    <h1 className=''><img className='locationicn' src={process.env.PUBLIC_URL + '/assets/images/icons/location.png'} />Delhi Himanchal 8 days package</h1>

                    <p className='subtestt'>Delhi-Chandigarh(1N)--Manali(03N)--Shimla(2N)--Chandigarh(1N)--Delhi(0N)--8D</p>

                </div>
                <div className='white-bggg'>
                    <div>
                        <h5 className='title-firsttt'>Delhi</h5>
                        <p className='subtitle-package-details'>Delhi, capital city of the Republic of India. It is one of India's fastest growing cities. It has sprawled over the West Bank of the river Yamuna, straddling the river. The city has two distinct parts, Old Delhi & New Delhi. Old Delhi is centered on the Red Fort built by Emperor Shah Jehan between 1636 & 1658. The streets of Old Delhi are narrow & bustling. The beauty & serenity lies inside the courts of the main buildings. The city has several tourist attractions without which Delhi is not complete. From Red fort, Lotus Temple and Chandni Chowk to Modern art Gallery, Purana Quila and many more, Delhi boasts of an infinite range of touristy places. The Delhi Metro is another remarkable achievement of the city in the recent years.</p>
                        <h5 className='title-firsttt'>Shimla</h5>
                        <p className='subtitle-package-details'>Shimla, formerly known as Simla, is the capital city of the Indian state of Himachal Pradesh, located in northern India. It is bounded by Mandi and Kullu in the north, Kinnaur in the east, the state of Uttaranchal in the south-east, and Solan and Sirmaur to the south. The elevation of the city ranges from 300 to 6000 meters. Shimla is well known as a hub for India's tourism sector. It is among the top 10 preferred entrepreneurial locations in India. In addition to being the local hub of transportation and trade, Shimla is the area's healthcare center, hosting a medical college and four major hospitals. The Kalka-Shimla railway line constructed in 1906, added to Shimla's accessibility and popularity. The railway route from Kalka to Shimla has more than 806 bridges and 103 tunnels. In 2008, it became part of the UNESCO World Heritage Site, Mountain railways of India.</p>
                        <h5 className='title-firsttt'>Chandigarh</h5>
                        <p className='subtitle-package-details'>Chandigarh is a very popular tourist destination and heart for the business entrepreneurs lying to the north of Delhi. Chandigarh being the capital of both Punjab & Haryana and the entranceway to Himachal Pradesh, there are many individuals travelling from Delhi to Chandigarh, and vice versa. Amongst the numerous ways to travel from Delhi to Chandigarh, the most pleasurable mode is to travel by road. For a comfortable journey in luxuriant settings, book our taxi services in most affordable rates</p>
                        <h5 className='title-firsttt'>Manali</h5>
                        <p className='subtitle-package-details'>Manali at an altitude of 2,050 m in the Beas River Valley is a beautiful hill station nestled in the mountains of the Indian state of Himachal Pradesh near the northern end of the Kullu Valley. It is located about 270 km north of the state capital, Shimla. Manali with a population of approx. 30,000 is administratively a part of the Kullu district. The small town is the beginning of an ancient trade route to Ladakh and from there over the Karakoram Pass on to Yarkand and Khotan in the Tarim Basin. Manali and the surrounding area is of great significance to Indian culture and heritage as it is said to be the home of the Saptarishi,or Seven Sages</p>
                    </div>
                    <div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Day 1: <br /> Delhi To Chandigarh</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Day 2: <br /> Chandigarh To Manali</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Day 5: <br /> Manali To Shimla</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="four">Day 7: <br /> Shimla To Chandigarh</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="five">Day 8: <br /> Chandigarh To Delhi</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className='dark-brown-bggg'>
                                                <p className='subtitle-package-details'>Manali at an altitude of 2,050 m in the Beas River Valley is a beautiful hill station nestled in the mountains of the Indian state of Himachal Pradesh near the northern end of the Kullu Valley. It is located about 270 km north of the state capital, Shimla. Manali with a population of approx. 30,000 is administratively a part of the Kullu district. The small town is the beginning of an ancient trade route to Ladakh and from there over the Karakoram Pass on to Yarkand and Khotan in the Tarim Basin. Manali and the surrounding area is of great significance to Indian culture and heritage as it is said to be the home of the Saptarishi, or Seven Sages Overnight stay at a hotel in Manali</p>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className='dark-brown-bggg'>
                                                <p className='subtitle-package-details'>On the second Day driver to Manali. Enjoy a drive past Sundernagar, Pandoh Dam, and Aut & Kullu Valley. Reach Manali in the evening and check in to your hotel. </p>
                                                <p className='subtitle-package-details'>Next Day:- In the morning, after breakfast day free to explore Manali locally. Visit places like Vashishtha Kund, Hadimba Temple, Club House and Tibetan monasteries and Mall Road. Evening at leisure. Overnight stay at a hotel in Manali.</p>
                                                <p className='subtitle-package-details'> Next Day:- Have an early breakfast and proceed to Solang Valley. Evening at leisure. Overnight stay at the hotel in Manali.</p>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">Third tab content</Tab.Pane>
                                        <Tab.Pane eventKey="four">Four tab content</Tab.Pane>
                                        <Tab.Pane eventKey="five">Five tab content</Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                    <div className='mt-5'>
                        <h5 className='title-notes mb-4'>Inclusion:</h5>
                        <ol>
                            <li>Fuel Charges </li>
                            <li>2. A/c Vehicle for your personal use for transfers & Sightseeing </li>
                            <li>3. State/Permit and Toll Tax charges included for the stated itinerary. </li>
                            <li>Driver's day-driving allowances are included </li>
                        </ol>
                    </div>
                    <div className='mt-5'>
                        <h5 className='title-notes mb-4'>Exclusion:</h5>
                        <ol>
                            <li>Guide and Entrance Fee at any sightseeing points are NOT included </li>
                            <li> Other personal expenses incurred are to be borne by the client</li>
                            <li>Any additional activities</li>
                            <li>Any cost arising due to natural calamities like, landslides, road blockage, political disturbances (strikes), etc (to be borne by the client, which is directly payable on the spot)</li>
                            <li>Airport Entry /Parking Charges are to be paid separately on an actual basis </li>
                            <li>Any Meals/services expenses.</li>
                            <li>Visiting places or sightseeing points that are not mentioned in the itinerary is not permitted unless made part of the itinerary plan ahead of the trip start. Additional charges may be applicable for adding/changing the sightseeing plans.</li>
                        </ol>
                    </div>
                </div>
            </Container>

        </section>
    )
}

export default PackageDetailsText
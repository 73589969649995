import React, { useState } from 'react';
import './PackageHistory.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import RequestCallBack from '../requestcallback/RequestCallBack';
import Request_callback_modal from '../../common-components/modals/request-callback/Request_callback_modal';
import { Link } from 'react-router-dom';

const PackageHistory = () => {
    const [modalShow, setModalShow] = useState(false);

    const [swiperInstance, setSwiperInstance] = useState(null);

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
    // Package data array
    const packages = [
        {
            name: 'Delhi Himanchal 8 days package',
            imgSrc: process.env.PUBLIC_URL + '/assets/images/booking-story/img1.png'
        },
        {
            name: 'Delhi Himanchal 8 days package',
            imgSrc: process.env.PUBLIC_URL + '/assets/images/booking-story/img1.png'
        },
        {
            name: 'Delhi Himanchal 8 days package',
            imgSrc: process.env.PUBLIC_URL + '/assets/images/booking-story/img1.png'
        },
    ];

    return (
        <section className='history-package'>
            <div className='white-bggg-history'>
                <div className='text-center mb-4'>
                    <h5 className='history-title'>Packages</h5>
                </div>
                <Row>
                    <Swiper
                        slidesPerView={3.5}
                        onSwiper={(swiper) => setSwiperInstance(swiper)} 

                        spaceBetween={15}
                        className="mySwiper history-slider"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            485: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            576: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1200: {
                                slidesPerView: 2.5,
                                spaceBetween: 20,
                            },
                            1440: {
                                slidesPerView: 2.5,
                                spaceBetween: 15,
                            },
                        }}
                    >
                        {packages.map((pkg, index) => (
                            <SwiperSlide key={index} className='mb-5'>
                                <div className='main-package-slide'>
                                    <div className='img-ourwork-class'>
                                        <img src={pkg.imgSrc} className='package-img' alt={pkg.name} />
                                    </div>
                                    <div className='black-main'>
                                        <div className='main-class'>
                                            <div className='text-center'>
                                                <FontAwesomeIcon icon={faLocationDot} className='location-dot' />
                                            </div>
                                            <p className='location-namee'>{pkg.name}</p>
                                            <div className='white-sub-bggg'>
                                                <Link to="/package-details">
                                                    <button className='show-txttt'>Show Details</button>
                                                </Link>
                                                <button className='email-us-btnn' onClick={() => setModalShow(true)}>Call/ Email us to Book</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div class="silder-btn">
                                <div
                                    className="back-btn"
                                    onClick={() => handleMainSliderClick("prev")}
                                >
                                    <img className='abt-banner' src={process.env.PUBLIC_URL + '/assets/images/booking-story/leftarrow.png'} />
                                </div>
                                <div
                                    className="next-btn"
                                    onClick={() => handleMainSliderClick("next")}
                                >
                                    <img className='abt-banner' src={process.env.PUBLIC_URL + '/assets/images/booking-story/rightarrow.png'} />
                                </div>
                            </div>
                </Row>
            </div>

            <Request_callback_modal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </section>


    );
}

export default PackageHistory;

import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import NotesDisclaimer from './notesdisclaimer/NotesDisclaimer'
import PackageDetailsText from './packagedetailstext/PackageDetailsText'


const PackageDetails = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { pathname } = useLocation();
    return (
        <>
            <PackageDetailsText />
            <NotesDisclaimer />
        </>
    )
}

export default PackageDetails
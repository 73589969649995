import React, { useState } from 'react'
import './Request_callback_modal.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CallScheduleLottieModal from '../CallScheduleLottieModal/CallScheduleLottieModal';


const Request_callback_modal = (props) => {

    const [modalShow,setModalShow] = useState(false)

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Request_callback_modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Request Call Back
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(event) => {
                        event.preventDefault(); 
                        props.onHide();
                        setModalShow(true); 
                    }}>
                        <div className='row'>
                            <div className='col-sm-6 mb-3'>
                                <Form.Group className='form-group'>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter First Name " />
                                </Form.Group>
                            </div>
                            <div className='col-sm-6 mb-3'>
                                <Form.Group className='form-group'>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter last Name " />
                                </Form.Group>
                            </div>
                            <div className='col-sm-6 mb-3'>
                                <Form.Group className='form-group'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email " />
                                </Form.Group>
                            </div>
                            <div className='col-sm-6 mb-3'>
                                <Form.Group className='form-group'>
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Phone Number " />
                                </Form.Group>
                            </div>
                            <div className='col-sm-12 mb-3'>
                                <Form.Group className='form-group'>
                                    <Form.Label>Reson</Form.Label>
                                    <Form.Control type="text" placeholder="New Booking" />
                                </Form.Group>
                            </div>
                            <div className='col-sm-12 mb-3'>
                                <Form.Group className='form-group'>
                                    <Form.Label>Note for customer Agent</Form.Label>
                                    <FloatingLabel controlId="floatingTextarea2" label="Enter Description">
                                        <Form.Control as="textarea" placeholder="Enter Description" style={{ height: '100px' }} />
                                    </FloatingLabel>
                                </Form.Group>
                            </div>
                            <div className='col-sm-12 mb-3'>
                                <button className='submitbtn'>Call Me Back</button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>

            </Modal>

            <CallScheduleLottieModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                message="Your call has been successfully scheduled!"
                submessage="We'll be in touch soon!"
            />
        </>
    )
}

export default Request_callback_modal
import React from 'react'
import './key-serviceshome.css'

const Key_services = () => {
    return (
        <>
            <section className='Key_services_sec'>
                <div className='container'>
                    <div className='titlediv'>
                        <h4 className='title'>Key Services, Seamless Rides</h4>
                        <h6 className='subtitle'>Your Journey, Our Priority!</h6>
                    </div>

                    <div className='servicessec'>
                        <div className='row justify-content-center'>

                            <div className='col-xl-2 col-lg-3 col-md-3 col-sm-6 p-sm-0'>
                                <div className='service-box'>
                                    <h6 className='box-title'>Easy Booking Process</h6>
                                    <p className='box-cont'>Simple and intuitive booking
                                        system, allowing  users to
                                        schedule rides quickly and
                                        Efficiently</p>
                                </div>

                                <div className='service-box'>
                                    <h6 className='box-title'>Real-Time Ride Tracking</h6>
                                    <p className='box-cont'>GPS-enabled tracking so
                                        customers can monitor their
                                        ride’s arrival and journey in
                                        real-time.</p>
                                </div>

                                <div className='service-box'>
                                    <h6 className='box-title'>Secure Payment Options</h6>
                                    <p className='box-cont'>Multiple payment methods
                                        including credit/debit cards,
                                        digital wallets, and cash,
                                        all secured by the latest
                                        encryption technologies</p>
                                </div>
                            </div>

                            <div className='col-xxl-6 col-xl-7 col-lg-6 col-md-6'>
                                <div className='imgdiv'>
                                    <img className='carimg' src={process.env.PUBLIC_URL + '/assets/images/home/key-services/banner.png'} />
                                </div>
                            </div>

                            <div className='col-xl-2 col-lg-3 col-md-3 col-sm-6 p-sm-0'>
                                <div className='service-box'>
                                    <h6 className='box-title'>Upfront Pricing</h6>
                                    <p className='box-cont'>Clear and transparent pricing
                                        with fare estimates provided
                                        before booking</p>
                                </div>

                                <div className='service-box'>
                                    <h6 className='box-title'>24/7 Customer Support</h6>
                                    <p className='box-cont'>Round-the-clock assistance
                                        to address any queries or
                                        issues customers may have </p>
                                </div>

                                <div className='service-box'>
                                    <h6 className='box-title'>Driver Ratings & Reviews</h6>
                                    <p className='box-cont'>A system for customers to
                                        rate drivers and read reviews,
                                        ensuring a quality and
                                        trustworthy experience</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Key_services
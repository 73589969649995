import React from 'react'
import './Select_car.css'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import Car_total_modal from '../../common-components/modals/car-total-modal/Car_total_modal';


const Select_car = () => {

    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <section className='Select_car_sec'>
                <div className='container-lg'>
                    <div className='banner-div'>
                        <img className='banner' src={process.env.PUBLIC_URL + '/assets/images/booking-form/banner.png'} />
                    </div>


                    <div className='row'>
                        <div className='col-md-11 mx-auto'>
                            <div className='all_sec'>
                                <div className='filter-div'>
                                    <div className='row'>
                                        <div className='col-md-6  my-md-auto my-2'>
                                            <div className='d-flex'>
                                                <div className='my-auto'>
                                                    <p className='fiterp'><img className='icon' src={process.env.PUBLIC_URL + '   /assets/images/icons/date.png'} />Pickup Date: <span>21/05/2024</span></p>
                                                </div>
                                                <div className='my-auto ms-5'>
                                                    <p className='fiterp'><img className='icon' src={process.env.PUBLIC_URL + '   /assets/images/icons/date.png'} />Pickup Date: <span>21/05/2024</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6 '>
                                            <div className='d-flex justify-content-md-end justify-content-between'>
                                                <div className='my-auto'>
                                                    <Link to="/booking-form"><button className='modify-btn'>Modify Sections</button></Link>
                                                </div>

                                                <div className='ms-4'>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                                            <img className='drop-icon' src={process.env.PUBLIC_URL + '/assets/images/icons/Glyph.png'} />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item >4 Hrs | 40 Km</Dropdown.Item>
                                                            <Dropdown.Item >8 Hrs | 80 Km</Dropdown.Item>
                                                            <Dropdown.Item >12 Hrs | 120 Km</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='vehiclediv'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-sm-6 mb-4'>
                                            <div className='cardiv'>
                                                <div className='car-title'>
                                                    <p className='title'>Indica</p>
                                                </div>
                                                <div className='img-div'>
                                                    <img className='img' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/car1.png'} />
                                                </div>

                                                <div className='info-div'>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/passenger.png'} />Seater</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>4</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/speedometer.png'} />Extra Km fare</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>₹ 13.5/Km after 131 Kms</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/air-conditioner.png'} />Facilities</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>Ac Conditional</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/gasoline-pump.png'} />Fuel</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>CNG With refill Breaks</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/cancel.png'} />Cancellation</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>Free till 1 Hour Of departure</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='totaldiv'>
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                            <p className='tot-amt'>Total Amount:</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <div className='d-flex'>
                                                                <p className='oldprice'>₹. <span>700</span></p>
                                                                <p className='oriprice'>₹. <span>594</span></p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <p className='smallprce'>₹. <span>494+</span></p>
                                                                <p className='smallprce'><img className='rupeeicn' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/rupee.png'} /> ₹. <span>100</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button className='next-btn' type="button" onClick={() => setModalShow(true)}><span>Next</span></button>

                                                <Accordion>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>View More</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul>
                                                                <li>Your Trip has a KM limit. If your usage exceeds this limit,
                                                                    you will be charged for the excess KM used.
                                                                </li>
                                                                <li>We promote cleaner fuel and thus your cab can be a CNG
                                                                    vehicle.
                                                                </li>
                                                                <li>The driver may need to fill CNG once or more during
                                                                    your trip. Please cooperate with the driver.
                                                                </li>
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                </Accordion>
                                            </div>
                                        </div>

                                        <div className='col-lg-4 col-sm-6 mb-4'>
                                            <div className='cardiv'>
                                                <div className='car-title'>
                                                    <p className='title'>Indica</p>
                                                </div>
                                                <div className='img-div'>
                                                    <img className='img' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/car1.png'} />
                                                </div>

                                                <div className='info-div'>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/passenger.png'} />Seater</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>4</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/speedometer.png'} />Extra Km fare</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>₹ 13.5/Km after 131 Kms</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/air-conditioner.png'} />Facilities</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>Ac Conditional</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/gasoline-pump.png'} />Fuel</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>CNG With refill Breaks</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/cancel.png'} />Cancellation</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>Free till 1 Hour Of departure</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='totaldiv'>
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                            <p className='tot-amt'>Total Amount:</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <div className='d-flex'>
                                                                <p className='oldprice'>₹. <span>700</span></p>
                                                                <p className='oriprice'>₹. <span>594</span></p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <p className='smallprce'>₹. <span>494+</span></p>
                                                                <p className='smallprce'><img className='rupeeicn' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/rupee.png'} /> ₹. <span>100</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button className='next-btn' type="button" onClick={() => setModalShow(true)}><span>Next</span></button>

                                                <Accordion>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>View More</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul>
                                                                <li>Your Trip has a KM limit. If your usage exceeds this limit,
                                                                    you will be charged for the excess KM used.
                                                                </li>
                                                                <li>We promote cleaner fuel and thus your cab can be a CNG
                                                                    vehicle.
                                                                </li>
                                                                <li>The driver may need to fill CNG once or more during
                                                                    your trip. Please cooperate with the driver.
                                                                </li>
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                </Accordion>
                                            </div>
                                        </div>

                                        <div className='col-lg-4 col-sm-6 mb-4'>
                                            <div className='cardiv'>
                                                <div className='car-title'>
                                                    <p className='title'>Indica</p>
                                                </div>
                                                <div className='img-div'>
                                                    <img className='img' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/car1.png'} />
                                                </div>

                                                <div className='info-div'>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/passenger.png'} />Seater</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>4</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/speedometer.png'} />Extra Km fare</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>₹ 13.5/Km after 131 Kms</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/air-conditioner.png'} />Facilities</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>Ac Conditional</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/gasoline-pump.png'} />Fuel</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>CNG With refill Breaks</p>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-1'>
                                                        <div className='col-5'>
                                                            <p className='infottt'><img className='icons' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/cancel.png'} />Cancellation</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <p className='infop'>Free till 1 Hour Of departure</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='totaldiv'>
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                            <p className='tot-amt'>Total Amount:</p>
                                                        </div>
                                                        <div className='col-7'>
                                                            <div className='d-flex'>
                                                                <p className='oldprice'>₹. <span>700</span></p>
                                                                <p className='oriprice'>₹. <span>594</span></p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <p className='smallprce'>₹. <span>494+</span></p>
                                                                <p className='smallprce'><img className='rupeeicn' src={process.env.PUBLIC_URL + '/assets/images/ride-vehicles/icons/rupee.png'} /> ₹. <span>100</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button className='next-btn' type='button' onClick={() => setModalShow(true)}><span>Next</span></button>

                                                <Accordion>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>View More</Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul>
                                                                <li>Your Trip has a KM limit. If your usage exceeds this limit,
                                                                    you will be charged for the excess KM used.
                                                                </li>
                                                                <li>We promote cleaner fuel and thus your cab can be a CNG
                                                                    vehicle.
                                                                </li>
                                                                <li>The driver may need to fill CNG once or more during
                                                                    your trip. Please cooperate with the driver.
                                                                </li>
                                                            </ul>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Car_total_modal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Select_car
import React from 'react'
import Select from 'react-select'
import Form from 'react-bootstrap/Form';

const Package_Form = () => {

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ];

    return (
        <section className='package_form_main_sec'>
            <div className='container-fluid p-0'>
                <div className="firsttabinner">
                    <div className="formbox">
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mb-2">
                                <Form.Group className="formgroup">
                                    <Form.Label>Going From</Form.Label>
                                    <Select options={options} />
                                </Form.Group>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-2">
                                <Form.Group className="formgroup">
                                    <Form.Label>Min No. of Nights</Form.Label>
                                    <Form.Control type="number" placeholder="Enter Number of Days" />
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-sm-6 mb-2">
                                <Form.Group className="formgroup">
                                    <Form.Label>Max No. of Nights</Form.Label>
                                    <Form.Control type="number" placeholder="Enter Number of Days" />
                                </Form.Group>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Package_Form

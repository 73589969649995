import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import { Col, Container, Row } from 'react-bootstrap';
import './RoutesPage.css';
import { Link } from 'react-router-dom';

// Array of route data
const routesData = [
    {
        title: 'One Way Taxi From Mumbai',
        routes: [
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad City Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
        ]
    },
    {
        title: 'One Way Taxi From Pune',
        routes: [
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad City Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
        ]
    },
    {
        title: 'One Way Taxi From Pune',
        routes: [
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad City Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
        ]
    },
    {
        title: 'One Way Taxi From Pune',
        routes: [
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad City Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
        ]
    },
    {
        title: 'One Way Taxi From Pune',
        routes: [
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad City Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
        ]
    },
    {
        title: 'One Way Taxi From Pune',
        routes: [
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad City Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
        ]
    },
    {
        title: 'One Way Taxi From Pune',
        routes: [
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad City Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
        ]
    },
    {
        title: 'One Way Taxi From Pune',
        routes: [
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad City Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
        ]
    },
    {
        title: 'One Way Taxi From Pune',
        routes: [
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad City Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Ahmedabad Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
            'Mumbai To Aamby Valley Cab',
        ]
    },
];

const RoutesPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();
    return (
        <section className='routes'>
            <div className='container-fluid p-0'>
                <div className='bgbannnerdiv'>
                    <img className='banner' src={process.env.PUBLIC_URL + '/assets/images/routs/banner.png'} />
                </div>

                <Container>
                    <Row>
                        {routesData.map((route, index) => (
                            <Col lg={4} md={6} sm={12} xs={12} key={index}>
                                <div className='main-card-routes'>
                                    <h5 className='title-rotes-card'>{route.title}</h5>
                                    {route.routes.map((subRoute, idx) => (
                                        <Link to='/routes-inner'>
                                            <p className='sub-txtt-routes' key={idx}>
                                                {subRoute}
                                            </p>
                                        </Link>

                                    ))}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </section>
    );
};

export default RoutesPage;

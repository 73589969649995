import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import "./Cab_Modal.css";
import Thankyou_success from "../thank-you-booking-modal/Animation/Thankyou_success";
import Passenger_details_modal from "../passenger-details-modal/Passenger_details_modal";

const Cab_Modal = (props) => {


  const [modalShow, setModalShow] = useState(false);

  // Automatically close Meet_at_pickup_modal after 3 seconds and open Contactandpickup_modal
  useEffect(() => {
    if (props.show) {
      const timer = setTimeout(() => {
        setModalShow(true);  // Show Contactandpickup_modal
        props.onHide();      // Hide Meet_at_pickup_modal
      }, 3000); // 3000ms = 3 seconds

      return () => clearTimeout(timer); // Cleanup timer on component unmount or re-render
    }
  }, [props.show]);


  return (
    <>
      <Modal
        {...props}
        size=""
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=" Cab_Modal"
      >
        <Modal.Body>
          <div className="animation-div">
            <Thankyou_success />
          </div>
        </Modal.Body>
      </Modal>


      <Passenger_details_modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Cab_Modal;

import React from 'react'
import './Icon_cab.css'

const Icon_cab = () => {
  return (
    <>
        <section className="iconcab_bigtext" >
            <div className='container'>
                <h1 className='icontext'>Icon Cab</h1>
            </div>
        </section>
    </>
  )
}

export default Icon_cab
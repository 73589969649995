import React from 'react'
import './Cancel_ride_modal.css'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CancelRideLottieModal from './CancelRideLottie/CancelRideLottieModal';

const Cancel_ride_modal = (props) => {

  const [modalShow, setModalShow] = React.useState(false);


  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='Cancel_ride_modal'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4 className='res-titl'>Reasons For the Ride Cancellation</h4>
          <div className='reasonlist'>
            <Form >
              {['radio'].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    label="My plans have changed, and I no longer need the ride"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />

                  <Form.Check
                    label="I booked the cab by mistake"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />

                  <Form.Check
                    label="I found another way to trave"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />

                  <Form.Check
                    label="I booked the cab by mistake"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />

                  <Form.Check
                    label="The cab is taking too long to arrive"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />

                  <Form.Check
                    label="The fare is higher than expected"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />

                  <Form.Check
                    label="I found another way to trave"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />

                  <Form.Check
                    label="The fare is higher than expected"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />

                  <Form.Check
                    label="Other"
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                  />


                  <FloatingLabel controlId="floatingTextarea2" label="Write the Reason">
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: '100px' }}
                    />
                  </FloatingLabel>

                </div>
              ))}

              <div className='row'>
                <div className='col-6'>
                  <button className='keepridebtn' type='button'>Keep my  Ride</button>
                </div>
                <div className='col-6'>
                  <button onClick={() => {
                    props.onHide()
                    setModalShow(true);
                  }} className='cancelridebtn' type='button'>Cancel Ride</button>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>

      </Modal>

      <CancelRideLottieModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        message="Your booking has been successfully canceled."
        submessage="We’re sorry to see you go!"

      />
    </>
  )
}

export default Cancel_ride_modal
import React from 'react'
import { Container } from 'react-bootstrap'
import './BookCab.css'

const BookCab = () => {
    return (
        <section className='book-cab'>
            <div className='container-fluid p-0'>
                <Container className='content'>
                    <h3 className='title'>Book Delhi to Agra Cab</h3>
                    <h6 className='sub-title'>Delhi to Agra distance: 230 km | Estimated travel time: 4 hours</h6>

                    <p className='description'>
                        Delhi to Agra is the very popular route for both domestic as well as foreign tourists.The main attraction of course is Taj Mahal, one of the seven wonders of the World. Placed on the banks of a river Yamuna, Agra is the most famous international holiday destination having three World Heritage Sites – Taj Mahal, Agra Red Fort, Fatehpur Sikri.The city is a heaven of architecture, history, art and culture. Its mouth-watering Agraka Petha and Dal Moth which are quite famous too. The best way to reach Agra from Delhi is by road and it takes 3 to 4 hours to go from Delhi to Agra by car. The road distance from Delhi to Agra is 230 km. You can also take a train from Delhi to Agra By train it is 195 km and takes 2.5 to 3 hours. Delhi to Agra taxi fare is approx. Rs. 1800 for a good sedan car like Etios, Dzireand is approx. Rs. 2700 for SUV for 7 passengers. For best price on your travel date for various car rental options, please fill in above form.
                    </p>
                </Container>
            </div>
        </section>
    )
}

export default BookCab

import React from 'react'
import '../Thank_you_booking.css'
import Lottie from 'react-lottie';
import * as animationData from './cab.json';

function Thankyou_success() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    return (
        <>
            <section className='Thankyou_success'>
                <div className='vdo-btn'>
                    <Lottie className="me-auto"
                        options={defaultOptions}
                    />
                </div>
            </section>
        </>
    )
}

export default Thankyou_success
import React from 'react'

import './CommonLogin.css'
import { Form } from 'react-bootstrap'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faEye } from '@fortawesome/free-regular-svg-icons'

const SetNewPassword = () => {
    return (
        <>
            <section className='main-class-common'>
                <div className='container'>
                    <div className='row row-height'>
                        <div className='col-lg-4 my-auto'>
                            <div className='authentication-form'>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/login-signup/reset-password.png'} className='login-user' />
                                    <p className='common-form-txttt mt-4'>Your<span className='red-txtt'> New password must be Different <Link to="#" className='red-txtt'></Link></span> from
                                        the previously used password.</p>
                                </div>
                                <Form className='mt-3'>


                                    <Form.Group className="form-group">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter your Password" />
                                        <FontAwesomeIcon className='passwordicon' icon={faEye} />
                                    </Form.Group>

                                    <Form.Group className="form-group">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter Confirm Password" />
                                        <FontAwesomeIcon className='passwordicon' icon={faEye} />
                                    </Form.Group>
                                   <Link to="/password-reset-successfully"><button className='sign-in-btnnn my-4'><span>Reset Password</span></button></Link>
                                </Form>
                                <div className='mt-3'>

                                    <div className='text-center'>
                                        <p className='common-form-txttt mb-0'>
                                            <Link to="/login">
                                                <img className='googg-img' src={process.env.PUBLIC_URL + '/assets/images/login-signup/backkk.png'} />
                                            </Link>
                                        </p>
                                        <Link to="" className='common-form-txttt mb-0'>Back To Sign In</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SetNewPassword
import React from 'react'
import './Abt_aboutus.css'

const Abt_aboutus = () => {
    return (
        <>
            <section className='Abt_aboutus_sec'>
                <div className='container'>
                    <div className='meet-title-div'>
                        <p className='meetti'><span>About</span> Us</p>
                    </div>
                    
                    <div className='contentsec'>
                        <p className='pcont'>icon Cab is India’s premier chauffeur-driven car rental service, dedicated to offering reliable, safe, and affordable cabs since 2006. With a presence in over 2,000 cities, we boast India’s largest geographical network of cab services. Our offerings include Outstation One-Way Drops, Outstation Round Trips, Hourly Local Rentals, and Airport Transfers. However, we aim to be more than just a car rental company. Our mission is to make every Indian fall in love with road travel. Picture yourself cruising along India’s winding roads, where ancient mountains share their timeless stories and vibrant saree-clad figures dance in marigold sunsets. The air is alive with diverse dialects and the melodic hum of hymns. Vendors serve spicy chaat, bazaars burst with colorful trinkets, and the aroma of incense from distant temples enhances your journey. What better way to immerse yourself in these rich experiences than from the comfort of a chauffeur-driven car?</p>

                        <ul>
                            <li>We understand what you’re looking for when booking an outstation taxi.</li>
                            <p className='ul-cont'>You want reliable transportation in an AC cab, available everywhere—not just in major cities like Delhi, Mumbai, Bengaluru, Hyderabad, Chennai, or Kolkata. You’re looking for affordable rates without compromising on service. While haggling is common in India, most of us prefer straightforward pricing. No guesswork, no hassle—you just want to book with one company that finds you a taxi that fits your budget and needs.</p>

                            <li>We deliver exactly what you need.</li>
                            <p className='ul-cont'>It’s important to note that it is a technology-driven marketplace—not a taxi operator. We connect you with taxi operators who want your business and are motivated to meet our high standards. Because we have established relationships with these operators, they’re more likely to meet our requests and deliver better service. This means a lower chance of delays, poor vehicle conditions, or other issues when you book through icon cabs. </p>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Abt_aboutus
import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import './ContactUs.css';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import Login_successful_modal from '../common-components/modals/login-successful-modal/Login_successful_modal'
import Logout_Successfull_Modal from "../common-components/modals/logout-modal/Logout_Modal/Logout_Successfull_Modal";

const ContactUs = () => {

    const [modalShow, setModalShow] = React.useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();
    return (
        <section className='contact-us'>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className='main-map-class'>
                        <iframe
                            className="gmap_iframe"
                            frameBorder="0"
                            scrolling="no"
                            marginHeight="0"
                            marginWidth="0"
                            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            style={{ width: "100%", height: "100%" }}
                        >

                        </iframe>
                    </div>
                </Col>
            </Row>
            <Container>
                <div className='white-bggg'>
                    <Row>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div>
                                <h5 className='title-contact'>Get in Touch with Us</h5>
                                <p className='contact-txttt'>
                                    We’re here to help! If you have any questions, concerns, or feedback,
                                    please feel free to reach out to us.
                                </p>
                                <div className='d-flex'>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/icons/Icon feather-phone-call.png'} className='contact-font-icon' />
                                    {/* <FontAwesomeIcon icon={faPhoneVolume} className='contact-font-icon' /> */}
                                    <p className='contact-txtt-sub'>+91 3445564333</p>
                                </div>
                                <div className='d-flex'>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/icons/Icon feather-mail.png'} className='contact-font-icon' />
                                    {/* <FontAwesomeIcon icon={faEnvelope} className='contact-font-icon' /> */}
                                    <p className='contact-txtt-sub'>Iconcabs@gmail.com</p>
                                </div>
                                <div className='d-flex'>
                                    <img src={process.env.PUBLIC_URL + '/assets/images/icons/address (1).png'} className='contact-font-icon' />
                                    {/* <FontAwesomeIcon icon={faLocationDot} className='contact-font-icon' /> */}
                                    <p className='contact-txtt-sub'>
                                        337, Old Ghtrd West, Tambaram, Chennai, Pune, 600045, India
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Form>
                                <Row>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formFirstName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formLastName">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" />
                                        </Form.Group>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Form.Group className="mb-3" controlId="formPhone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="tel" />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12}>
                                        <Form.Group className="mb-3" controlId="formMessage">
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control as="textarea" rows={4} />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12}>
                                        <Form.Group>
                                            <button type="button" onClick={() => setModalShow(true)} className="send-message">
                                                <span>Send Message</span>
                                            </button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>

            <Logout_Successfull_Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                message="Message sent successfully" // Passing a custom message
                
            />

        </section>
    );
};

export default ContactUs;

import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import { Col, Container, Nav, Row, Tab, Table } from 'react-bootstrap'
import './RoutesInner.css'
import Icon_cab from '../home/icon-cab/Icon_cab'
import { Link } from 'react-router-dom'
import Still_question from './Still_question'

const RoutesInner = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();
    return (
        <section className='routes-inner'>
            <div className='bgbannnerdiv'>
                <img className='banner' src={process.env.PUBLIC_URL + '/assets/images/routs/banner2.png'} />
            </div>
            <Container>
                <Row>
                    <Col xl={12}>
                        <div>
                            <h5 className='main-title-routes-inner'>Mumbai to Aamby Valley Cab Service</h5>
                        </div>
                        <div className='main-white-bggggg'>
                            <p className='common-txtt-routes'>One Way Travel Cab is your ultimate destination for Mumbai to Aamby Valley Cab Service. With us, you can find reliable and affordable taxi booking, one way cab, outstation cabs, and more. Whether you're traveling solo or with a group, we've got you covered. Explore Aamby Valley with ease and comfort by booking a cab with One Way Travel Cab. Our fleet of well-maintained cabs and experienced drivers ensure a hassle-free journey for you. Book your cab online from Mumbai to Aamby Valley at the best prices. Choose from a range of vehicles including Hatchbacks, Sedans, LUVs, SUVs, and MUVs. We offer transparent pricing and no hidden charges. Experience the convenience of cab booking online with One Way Travel Cab. With just a few clicks, you can reserve your cab and enjoy a comfortable journey from Mumbai to Aamby Valley. Why wait? Book your cab now and enjoy a seamless travel experience with One Way Travel Cab!</p>
                            <div>
                                <h5 className='sub-title-routes'>How much is a taxi fare from Mumbai to Aamby Valley?</h5>
                                <Table bordered className='routes-table'>
                                    <thead>
                                        <tr>
                                            <th>Cab Types</th>
                                            <th>Seats Bags</th>
                                            <th>Price Ranging from</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>AC HATCHBACK Maruti WagonR, Hyundai i10 or Similar</td>
                                            <td>4 Seats 2 Bags</td>
                                            <td>45% to 50% Discount</td>
                                        </tr>
                                        <tr>
                                            <td>AC HATCHBACK Maruti WagonR, Hyundai i10 or Similar</td>
                                            <td>4 Seats 2 Bags</td>
                                            <td>45% to 50% Discount</td>
                                        </tr>
                                        <tr>
                                            <td>AC HATCHBACK Maruti WagonR, Hyundai i10 or Similar</td>
                                            <td>4 Seats 2 Bags</td>
                                            <td>45% to 50% Discount</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className='mt-5'>
                                <h5 className='routes-common-routess'>Mumbai To Aamby Valley Taxi Services</h5>
                                <p className='common-txtt-routes'>Book Mumbai to Aamby Valley Taxi with One Way Travel Cab as it is the most reliable taxi service in India. Our well-maintained cabs, experienced and courteous drivers, make your trip at ease. By road from Mumbai to Aamby Valley, the distance is calculated as per “Google Map” and it may vary at times taking into consideration the traffic conditions.</p>
                                <p className='common-txtt-routes'>The ultimate time to reach your destination is by your pace and the number of times you take breaks on the way for various rest stops which may be found along the way, where you can replenish your supplies. Use One Way Travel Cab’s Taxi booking tool to plan your trip. Once you use Mumbai to Aamby Valley Cab with us be rest assured as this is going to be the best one-way trip ever.</p>
                                <p className='common-txtt-routes'>One Way Travel Cab provides the lowest rate and best service with no hidden charges for Mumbai to Aamby Valley car rental search, select and book your cab online for Mumbai from Aamby Valley. We Provide Taxis from Mumbai to Aamby Valleyfor Outstation (One Way and Round Trip) which includes the pickup from Mumbai and drop to your desired destination in Aamby Valley. We accept last minute booking and provide cabs instantly we are available at your service for 365 Days</p>
                                <h5 className='routes-common-routess'>Cab Booking Online</h5>
                                <p className='common-txtt-routes'>Those days are gone far behind when you had to wave your hand and wait on the road alongside looking for taxis or cabs. The smartphone facility in India has grown by leaps and bounds these days. Now simply, book your cab online from the point of pickup in Mumbai to your destination in Aamby Valley.</p>
                                <p className='common-txtt-routes' >Cab Booking Online saves your time with just a click on your smartphone, tablets, or laptops you can visit our website www.onewaytravel.cab further more, you have the option of ordering cab for One Way or Round Trip from Mumbai to Aamby Valley. Enjoy a quick and comfortable journey by using our website’s hassle-free Cab Booking Online service</p>
                                <h5 className='routes-common-routess'>Mumbai To Aamby Valley Onside Taxi Service</h5>
                                <p className='common-txtt-routes'>We provide a low-cost one-way taxi service in Mumbai to Aamby Valley, with an experienced driver and modern taxis. Enjoy the most comfortable road trip and build memories with skilled and verified drivers, different cab selections, and budget-friendly pricing. If you want to avail of a flight or train, take advantage of our Mumbai to Aamby Valley One Side Taxi service, which is affordably priced.</p>
                                <h5 className='routes-common-routess'>Mumbai To Aamby Valley Car Hire</h5>
                                <p className='common-txtt-routes'>You can find a car from Mumbai to Aamby Valley throughout the year at One Way Travel Cab and customize the tour package as per your requirements. Apart from cabs, you can check out rental cars at different prices. We offer a trusted car rental service that can make your journey a lot smoother. There are different kinds of car models listed on our website for you to choose from according to your choic, comfort, need, and budget.</p>
                                <h5 className='routes-common-routess'>Mumbai To Aamby Valley One Way Cab</h5>
                                <p className='common-txtt-routes'>You may get a Mumbai to Aamby Valley One Way Cab from a variety of car rental providers, accessible in Mumbai. If you book a cab through One Way Travel Cab website, you will be eligible for exclusive discounts and deals. We offer 45% to 50% discounts which include all the charges and transparent pricing. Mumbai to Aamby Valley One Way Travel Cab is not a shared cab, it is dedicated taxi service for you. Apart from Mumbai to Aamby Valley, We provide One Way Travel Cab for various other destinations of India. One Way Taxi Packages are designed by our team in such a way that you don’t have to pay for a return fare. travel one way and pay for One Way.</p>
                                <h5 className='routes-common-routess'>Outstation Cab Booking</h5>
                                <p className='common-txtt-routes'>If you are planning for an Outstation Cab Booking from Mumbai for a weekend, Short Distance or Long Distance Travelling, make a taxi reservation with One Way Travel Cab. We provide chauffer-driven cabs for Outstation (One Way, Round Trip), and Multi City Travel to and from Mumbai to almost every corner of India. Outstation Cab Rental allows you to travel from Mumbai to a particular place and return to Mumbai usually via the same route or as per your requirement.</p>
                                <p className='common-txtt-routes'>India is growing and emerging to provide best infrastructure and road connectivity in most of the Indian smart cities. Outstation travelling by car is the speediest option these days, with our official travel page fill in all data, such as your departure date and time, date and time of return, Pickup address, visiting destination, and required cab type. Our support team will assist you with cheapest fare and guide you to visit all of the must-see attractions and sampling the best local cuisine. Taxi from Mumbai to Aamby Valley may be found at any time of the year.</p>
                                <p className='common-txtt-routes'>You may personalize your travel package to meet your specific needs. Apart from taxis, you may look at Outstation Cab Booking offers at various transparent pricing listed below.</p>
                                <div>
                                    <h5 className='routes-common-routess'>Cab Type Seats & Luggage Pricing</h5>
                                    <Table bordered className='routes-table' >
                                        <thead>
                                            <tr>
                                                <th>Cab Types</th>
                                                <th>Seats Bags</th>
                                                <th>Price Ranging from</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>AC HATCHBACK Maruti WagonR, Hyundai i10 Or Similar </td>
                                                <td>4 Seats 2 Bags</td>
                                                <td>Rs.12/Km (CNG) Rs. 14/Km (Diesel) (Minimum Average
                                                    of 300 Kms Per Calendar Day) + DA (Driver Allowance)
                                                    Rs. 300/Day + Toll + Parking (If Applicable)</td>

                                            </tr>
                                            <tr>
                                                <td>AC HATCHBACK Maruti WagonR, Hyundai i10 Or Similar </td>
                                                <td>4 Seats 2 Bags</td>
                                                <td>Rs.12/Km (CNG) Rs. 14/Km (Diesel) (Minimum Average
                                                    of 300 Kms Per Calendar Day) + DA (Driver Allowance)
                                                    Rs. 300/Day + Toll + Parking (If Applicable)</td>

                                            </tr>
                                            <tr>
                                                <td>AC HATCHBACK Maruti WagonR, Hyundai i10 Or Similar </td>
                                                <td>4 Seats 2 Bags</td>
                                                <td>Rs.12/Km (CNG) Rs. 14/Km (Diesel) (Minimum Average
                                                    of 300 Kms Per Calendar Day) + DA (Driver Allowance)
                                                    Rs. 300/Day + Toll + Parking (If Applicable)</td>

                                            </tr>
                                            <tr>
                                                <td>AC HATCHBACK Maruti WagonR, Hyundai i10 Or Similar </td>
                                                <td>4 Seats 2 Bags</td>
                                                <td>Rs.12/Km (CNG) Rs. 14/Km (Diesel) (Minimum Average
                                                    of 300 Kms Per Calendar Day) + DA (Driver Allowance)
                                                    Rs. 300/Day + Toll + Parking (If Applicable)</td>

                                            </tr>
                                            <tr>
                                                <td>AC HATCHBACK Maruti WagonR, Hyundai i10 Or Similar </td>
                                                <td>4 Seats 2 Bags</td>
                                                <td>Rs.12/Km (CNG) Rs. 14/Km (Diesel) (Minimum Average
                                                    of 300 Kms Per Calendar Day) + DA (Driver Allowance)
                                                    Rs. 300/Day + Toll + Parking (If Applicable)</td>

                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                                <div>
                                    <h5 className='routes-common-routess'>What Sets One Way Travel Cab Apart?</h5>
                                    <p className='common-txtt-routes'>Dedicated fleet of well-maintained cabs with transparent pricing and online payment facility</p>
                                    <p className='common-txtt-routes'>Highest turn-around time for Cabs dispatches 30 Min.</p>
                                    <p className='common-txtt-routes'>Cab for hire made easy through Online Cab booking in addition to calling a cab via phone or WhatsApp</p>
                                    <p className='common-txtt-routes'>24x7 call support facility having a dedicated team of polite, courteous, and responsible customer service executives</p>
                                </div>
                                <div className='mt-5'>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                                        <Row className='d-flex justify-content-center' >
                                            <Nav variant="pills" className="flex-row ">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first">Local Hourly Packages</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">Airport Transfers</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third">Airport Transfers</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first" className="mt-5">
                                                    <p className='common-txtt-routes text-center'>Explore your city at your own pace with our Local Hourly Packages. Whether you need transportation for errands, sightseeing, or attending events, One Way Travel Cab offers flexible options tailored to your needs.</p>
                                                    <h5 className='sub-title-routes text-center'>Why Choose Our Local Hourly Packages?</h5>
                                                    <Row className='mt-4'>
                                                        <Col lg={3} md={4} sm={6} xs={12} className='mb-4'>
                                                            <div className='dark-brown-bggg'>
                                                                <h5>Convenience: </h5>
                                                                <p className='common-txtt-routes'>Enjoy the convenience of having a cab at your disposal for as long as you need it. No need to worry about finding parking or navigating through traffic.</p>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} md={4} sm={6} xs={12} className='mb-4'>
                                                            <div className='dark-brown-bggg'>
                                                                <h5>Convenience: </h5>
                                                                <p className='common-txtt-routes'>Enjoy the convenience of having a cab at your disposal for as long as you need it. No need to worry about finding parking or navigating through traffic.</p>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} md={4} sm={6} xs={12} className='mb-4'>
                                                            <div className='dark-brown-bggg'>
                                                                <h5>Convenience: </h5>
                                                                <p className='common-txtt-routes'>Enjoy the convenience of having a cab at your disposal for as long as you need it. No need to worry about finding parking or navigating through traffic.</p>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} md={4} sm={6} xs={12} className='mb-4'>
                                                            <div className='dark-brown-bggg'>
                                                                <h5>Convenience: </h5>
                                                                <p className='common-txtt-routes'>Enjoy the convenience of having a cab at your disposal for as long as you need it. No need to worry about finding parking or navigating through traffic.</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className='mt-5'>
                                                        <div className='text-enter'>
                                                            <h5 className='sub-title-routes text-center mb-4'>How It Works</h5>
                                                        </div>
                                                        <Row>
                                                            <Col lg={3} md={4} sm={6} xs={12} className='mb-4'>
                                                                <div className='pink-brown-bggg'>
                                                                    <h5 >Book Your Package: </h5>
                                                                    <p className='common-txtt-routes'>Choose the desired duration for your local travel package when booking your cab. You can select from hourly packages based on your estimated travel time.</p>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={4} sm={6} xs={12} className='mb-4'>
                                                                <div className='pink-brown-bggg'>
                                                                    <h5>Enjoy Your Ride:</h5>
                                                                    <p className='common-txtt-routes'>Sit back, relax, and enjoy your ride as our professional driver takes you to your destinations. Whether it's running errands or exploring the city, we ensure a smooth and hassle-free experience..</p>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={4} sm={6} xs={12} className='mb-4'>
                                                                <div className='pink-brown-bggg'>
                                                                    <h5>Flexible Stops: </h5>
                                                                    <p className='common-txtt-routes'> Need to make multiple stops during your journey? No problem! Our drivers are flexible and accommodating, allowing you to make detours or additional stops as needed.</p>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={4} sm={6} xs={12} className='mb-4'>
                                                                <div className='pink-brown-bggg'>
                                                                    <h5>Seamless Payments:  </h5>
                                                                    <p className='common-txtt-routes'>Pay for your local hourly package seamlessly through our online payment system or cash payments to the driver. We offer transparent pricing with no hidden charges.</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div >
                                                        <h5 className='routes-common-routess text-center mt-5 mb-5'>Popular Local Hourly Packages.</h5>
                                                        <ul>
                                                            <li>4-Hours 40 Kms Package: Perfect for exploring multiple attractions or attending events around the city.</li>
                                                            <li>8-Hours 80 Kms Package: Great for a full day of sightseeing or attending special occasions with friends and family.</li>
                                                            <li>10-Hours 100 Kms package: Book today and enjoy a full day of adventure and discovery with One Way Travel Cab</li>
                                                        </ul>
                                                        <p className='common-txtt-routes'>Book Your Local Hourly Package, Experience the convenience and flexibility of our Local Hourly Packages. Book your package now and enjoy hassle-free local travel with One Way Travel Cab.</p>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <p className='common-txtt-routes text-center mt-5'>Experience Hassle-Free Travel to and from the Airport, Make your journey to or from the airport seamless and stress-free with our reliable airport transfer services. At One Way Travel Cab, we understand the importance of timely and comfortable transportation, whether you're heading to catch a flight or arriving at your destination.</p>
                                                    <h5 className='sub-title-routes text-center'>Why Choose Our Local Hourly Packages?</h5>
                                                    <Row className='mt-4'>
                                                        <Col lg={3} md={4} sm={6} xs={12} >
                                                            <div className='dark-brown-bggg'>
                                                                <h5>Convenience: </h5>
                                                                <p className='common-txtt-routes'>Enjoy the convenience of having a cab at your disposal for as long as you need it. No need to worry about finding parking or navigating through traffic.</p>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} md={4} sm={6} xs={12} >
                                                            <div className='dark-brown-bggg'>
                                                                <h5>Convenience: </h5>
                                                                <p className='common-txtt-routes'>Enjoy the convenience of having a cab at your disposal for as long as you need it. No need to worry about finding parking or navigating through traffic.</p>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} md={4} sm={6} xs={12} >
                                                            <div className='dark-brown-bggg'>
                                                                <h5>Convenience: </h5>
                                                                <p className='common-txtt-routes'>Enjoy the convenience of having a cab at your disposal for as long as you need it. No need to worry about finding parking or navigating through traffic.</p>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} md={4} sm={6} xs={12} >
                                                            <div className='dark-brown-bggg'>
                                                                <h5>Convenience: </h5>
                                                                <p className='common-txtt-routes'>Enjoy the convenience of having a cab at your disposal for as long as you need it. No need to worry about finding parking or navigating through traffic.</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className='mt-5'>
                                                        <div className='text-enter'>
                                                            <h5 className='sub-title-routes text-center mb-4'>How It Works</h5>
                                                        </div>
                                                        <Row>
                                                            <Col lg={3} md={4} sm={6} xs={12} >
                                                                <div className='pink-brown-bggg'>
                                                                    <h5 >Book Your Package: </h5>
                                                                    <p className='common-txtt-routes'>Choose the desired duration for your local travel package when booking your cab. You can select from hourly packages based on your estimated travel time.</p>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={4} sm={6} xs={12} >
                                                                <div className='pink-brown-bggg'>
                                                                    <h5>Enjoy Your Ride:</h5>
                                                                    <p className='common-txtt-routes'>Sit back, relax, and enjoy your ride as our professional driver takes you to your destinations. Whether it's running errands or exploring the city, we ensure a smooth and hassle-free experience..</p>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={4} sm={6} xs={12} >
                                                                <div className='pink-brown-bggg'>
                                                                    <h5>Flexible Stops: </h5>
                                                                    <p className='common-txtt-routes'> Need to make multiple stops during your journey? No problem! Our drivers are flexible and accommodating, allowing you to make detours or additional stops as needed.</p>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={4} sm={6} xs={12} >
                                                                <div className='pink-brown-bggg'>
                                                                    <h5>Seamless Payments:  </h5>
                                                                    <p className='common-txtt-routes'>Pay for your local hourly package seamlessly through our online payment system or cash payments to the driver. We offer transparent pricing with no hidden charges.</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">Second tab content</Tab.Pane>
                                            </Tab.Content>
                                        </Row>
                                    </Tab.Container>
                                </div>

                                < Still_question />



                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Icon_cab />

        </section>
    )
}

export default RoutesInner
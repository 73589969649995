import React, { useState } from 'react';
import './Logout_modal.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Login_successful_modal from '../login-successful-modal/Login_successful_modal';
import Logout_Successfull_Modal from './Logout_Modal/Logout_Successfull_Modal';

const Logout_modal = (props) => {
    // State to control visibility of Login_successful_modal
    const [showLoginModal, setShowLoginModal] = useState(false);

    // Function to handle "Yes" button click
    const handleYesClick = () => {
        props.onHide();  // Close Logout_modal
        setShowLoginModal(true);  // Open Login_successful_modal
    };

    return (
        <>
            <Modal
                {...props}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="Logout_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img className='logouticon' src={process.env.PUBLIC_URL + '/assets/images/icons/logout.png'} alt="logout" />
                    <h4 className='messg'>Do you want to log out From <br></br> <span>the Cab icon?</span></h4>
                    <div className='row mt-4'>
                        <div className='col-6'>
                            <button className='nobtn' onClick={props.onHide}>No</button>
                        </div>
                        <div className='col-6'>
                            <button className='yesbtn' onClick={handleYesClick}>Yes</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Login_successful_modal */}
            <Logout_Successfull_Modal
                show={showLoginModal}
                onHide={() => setShowLoginModal(false)}  // Close when the modal is dismissed
                 message=" You Are Now Logout" // Passing a custom message
                submessage ="Safe Travel Until Next Time"
            />
        </>
    );
};

export default Logout_modal;
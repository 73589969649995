import React from 'react'
import './attach-taxisec.css'
import { Link } from 'react-router-dom'

const Attach_taxi = () => {
    return (
        <>
            <section className='Attach_taxi_section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='sec1'>
                                <img className='taxiimg' src={process.env.PUBLIC_URL + '/assets/images/home/attach-taxi/taxi.png'} />    
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='sec2'>
                                <h5 className='attachtitle'>Attach your taxi</h5>
                                <div className='contdiv'>
                                    <h6>Boost Your Earnings: </h6>
                                    <p className='content'>Join our platform and get access to more ride requests, helping you maximize
                                    your income with flexible working hours</p>
                                </div>
                                <div className='contdiv'>
                                    <h6>Reliable Payments:</h6>
                                    <p className='content'>Enjoy consistent and timely payouts with transparent fare structures and no
                                    hidden fees.</p>
                                </div>
                                <div className='contdiv'>
                                    <h6>Seamless Onboarding:</h6>
                                    <p className='content'>Quick and easy registration process with 24/7 support to get you on the road faster.</p>
                                </div>
                                <div className='contdiv'>
                                    <h6>Expand Your Reach:</h6>
                                    <p className='content'>Tap into a large customer base and receive more ride requests in your area, giving
                                    your taxi greater visibility and demand.</p>
                                </div>

                                <div className='contdiv'>
                                    <Link className='readmore' to="/attach-taxi">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Attach_taxi
import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import Booking_breadcrumb from '../booking-form/booking-breadcrumb/Booking_breadcrumb'
import Icon_cab from '../home/icon-cab/Icon_cab'
import Select_car from './select-car/Select_car'

const Select_ride = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();
    return (
        <>
            <Booking_breadcrumb />
            <Select_car />
            <Icon_cab />
        </>
    )
}

export default Select_ride
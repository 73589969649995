import React from 'react'
import './PopularRideBanner.css'
import { Container } from 'react-bootstrap'

const PopularRideBanner = () => {
    return (
        <section className='popular-ride'>
            <div className='container-fluid p-0'>
                <Container className='content'>
                    <div className='row justify-content-center'> {/* Center the row */}
                        <div className=''>
                            <div className='row img-main-holder'>
                                <div className='col-lg-6 col-md-6  col-sm-6 img-holder'>
                                    <img className='popular-banner' src={process.env.PUBLIC_URL + '/assets/images/popular-ride/img1.png'} />
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-6   img-holder'>
                                    <img className='popular-banner' src={process.env.PUBLIC_URL + '/assets/images/popular-ride/img2.png'} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row justify-content-center'> {/* Center the row */}
                        <div className='card-section'>
                            <div className='row justify-content-center'>
                                <div className='col-lg-5 col-md-6 col-sm-6  card-holder'>
                                    <p>
                                        "You know Shah Jahan, life and youth, wealth and glory, they all drift away in thecurrent of time.You strove therefore, to perpetuate only the sorrow of your heart?Let the splendor of diamond, pearl and ruby vanish? Only let this one teardrop, thisTaj Mahal,glisten spotlessly bright on the cheek of time, forever and ever."
                                    </p>
                                    <div className='card-footer'>
                                        <label>- Rabindranath Tagore,,</label><br/>
                                        <label>Nobel awardee in literature</label><br/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Container>
            </div>
        </section>
    )
}

export default PopularRideBanner

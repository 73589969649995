import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Passenger_details_modal.css'
import { Link } from 'react-router-dom';

const Passenger_details_modal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Passenger_details_modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='contentdiv'>
                        <h6 className='passttt'>Passenger details</h6>

                        <div className='row mb-1'>
                            <div className='col-5'><p className='text'>Name and surname</p></div>
                            <div className='col-7'><p className='text'>pratiksha</p></div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-5'><p className='text'>Mobile number</p></div>
                            <div className='col-7'><p className='text'>8317268809</p></div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-5'><p className='text'>Email address</p></div>
                            <div className='col-7'><p className='text'>pratiksha@profcyma.com</p></div>
                        </div>

                       
                    </div>

                    <div className='contentdiv'>
                        <h6 className='passttt'>Booking details</h6>

                        <div className='row mb-1'>
                            <div className='col-5'><p className='text'>Itinerary</p></div>
                            <div className='col-7'><p className='text'>Pune <span> > </span> LonavalaPickup</p></div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-5'><p className='text'>Date</p></div>
                            <div className='col-7'><p className='text'>21st August 2024 </p></div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-5'><p className='text'>Time</p></div>
                            <div className='col-7'><p className='text'>7:00 AM</p></div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-5'><p className='text'>Car Type</p></div>
                            <div className='col-7'><p className='text'>Wagon R or Equivalent</p></div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-5'><p className='text'>KMs Included</p></div>
                            <div className='col-7'><p className='text'>68 km</p></div>
                        </div>
                        <div className='row mb-1'>
                            <div className='col-5'><p className='text'>Payment method</p></div>
                            <div className='col-7'><p className='text'>Online</p></div>
                        </div>
                    </div>


                    <h6 className='passttt'>Total Fare :</h6>
                    <h6 className='passttt'>₹ 1707 + Parking (if applicable) Toll & all other charges Included</h6>

                    <Link to="/where-cab"><button className='tractbtn'>Track the Location</button></Link>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Passenger_details_modal
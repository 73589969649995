import React, { useEffect, useState } from "react";
import "./Header.css";

import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronDown,
  faMagnifyingGlass,
  faShoppingCart,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { Col, NavDropdown, Row, Tab } from "react-bootstrap";
import { faFacebookF, faInstagram, faYoutube, } from "@fortawesome/free-brands-svg-icons";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from "react-bootstrap/Accordion";

import Header_offcanvas from "../common-components/offcanvas/Header-offcanvas/Header_offcanvas";
import Logout_modal from "../common-components/modals/logout-modal/Logout_modal";

function Header() {


  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    setActiveKey(null);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);    
  const { pathname } = useLocation();

  return (
    <>
      <section className='main-header'>
        <div className="container">
          <div className="headersec">
            <div className="tophead">
              <div className="row">
                <div className="col-sm-6">
                  <div className="d-flex">
                    <div>
                      <p className="contdetail"><img className="conticons" src={process.env.PUBLIC_URL + '/assets/images/icons/call.png'} />+91 3445564333</p>
                    </div>
                    <div className="bdside"></div>
                    <div>
                      <p className="contdetail"><img className="conticons" src={process.env.PUBLIC_URL + '/assets/images/icons/mail.png'} />Iconcabs@gmail.com</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex justify-content-end">
                    <div>
                      <p className="contdetail"><img className="conticons" src={process.env.PUBLIC_URL + '/assets/images/icons/address.png'} />337 .old Ghtrd West Tambaram, Chennai,Pune,600045,India</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="navbardiv">
            <Navbar expand="lg">
              <Navbar.Brand as={Link} to="/">
                <img
                  className="headlogo"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/logo/logo.png"
                  }
                  alt="Logo"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav ">
                <div></div>
                <Nav className="">
                  <Nav.Link
                    as={Link}
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}
                    onClick={handleNavItemClick}
                  >
                    Home
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to="/aboutus"
                    className={location.pathname === "/aboutus" ? "active" : ""}
                    onClick={handleNavItemClick}
                  >
                    Why Choose Icon Cabs?
                  </Nav.Link>



                  <Nav.Link
                    as={Link}
                    to="/our-services"
                    className={location.pathname === "/our-services" ? "active" : ""}
                    onClick={handleNavItemClick}
                  >
                    Our Services
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to="/routes"
                    className={location.pathname === "/routes" ? "active" : ""}
                    onClick={handleNavItemClick}
                  >
                    Routes
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to="/blogs"
                    className={location.pathname === "/blogs" ? "active" : ""}
                    onClick={handleNavItemClick}
                  >
                    Blogs
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to="/contact"
                    className={location.pathname === "/contact" ? "active" : ""}
                    onClick={handleNavItemClick}
                  >
                    Contact
                  </Nav.Link>


                  <Nav.Link as={Link} to="#" onClick={handleNavItemClick}>
                    <img onClick={handleShow1} className="useicn" src={process.env.PUBLIC_URL + '/assets/images/icons/user.png'} />
                  </Nav.Link>

                  <Nav.Link as={Link} to="/login" onClick={handleNavItemClick}>
                    <img onClick={handleShow1} className="useicn" src={process.env.PUBLIC_URL + '/assets/images/icons/user.png'} />
                  </Nav.Link>

                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </section>


      {['end'].map((placement, idx) => (
        <Offcanvas className="header-sidebar" placement="end" show={show1} onHide={handleClose1}>
          <Offcanvas.Body>
            <div className="headlist">
              <div className="list">
                <Link to="/book-history" onClick={handleClose1}>
                  <img className="icon" src={process.env.PUBLIC_URL + '/assets/images/header/watch.png'} />
                  <span>Booking History</span>
                </Link>
              </div>
              <div className="list">
                <Link to="/account-setting" onClick={handleClose1}>
                  <img className="icon" src={process.env.PUBLIC_URL + '/assets/images/header/setting.png'} />
                  <span>Account settings</span>
                </Link>
              </div>
              <div className="list">
                <Link to="/" onClick={handleClose1} >
                  <img className="icon" src={process.env.PUBLIC_URL + '/assets/images/header/logout.png'} />
                  <span onClick={() => setModalShow(true)}>Logout</span>
                </Link>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      ))}


      <Logout_modal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    </>
  );
}

export default Header;

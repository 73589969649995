import React, { useEffect, useRef, useState, useContext } from "react";
import Abt_banner from './abt-banner/Abt_banner'
import Abt_meet_team from './abt-team/Abt_meet_team'
import Abt_aboutus from './abt-aboutus/Abt_aboutus'
import Abt_whychoose from './abt-whychoose/Abt_whychoose'
import { useLocation } from "react-router-dom"

const About_us = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    
      const { pathname } = useLocation();
  
    return (
        <>
            <Abt_banner />
            <Abt_meet_team />
            <Abt_aboutus />
            <Abt_whychoose />
        </>
    )
}

export default About_us
import { useState, useEffect, } from 'react';
import "./App.css";
import "./index.css";

import { useLocation } from "react-router-dom"

import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import About_us from "./components/about-us/About_us";
import RoutesPage from "./components/routespage/RoutesPage";
import ContactUs from "./components/contact-us/ContactUs";
import Our_services from "./components/our-services/Our_services";
import Booking_form from "./components/booking-form/Booking_form";
import BlogsPage from "./components/blogspage/BlogsPage";
import BlogInner from "./components/bloginnerpage/BlogInner";
import RoutesInner from "./components/routesinner/RoutesInner";
import Select_ride from "./components/select-ride/Select_ride";
import BookingHistory from "./components/bookinghistory/BookingHistory";
import PackageDetails from "./components/packagedetails/PackageDetails";
import Login from "./components/login-signup/Login";
import Signup from './components/login-signup/Signup';
import ResetPassword from "./components/login-signup/ResetPassword";
import Account_setting from "./components/account-setting/Account_setting";
import Edit_account_setting from "./components/account-setting/Edit_account_setting";
import Set_Otp from './components/login-signup/Set_Otp';
import SetNewPassword from './components/login-signup/SetNewPassword';
import Password_reset_successfully from './components/login-signup/Password_reset_successfully';
import Terms_conditions from './components/terms_policy/Terms_conditions';
import Disclaimer from './components/terms_policy/Disclaimer';
import Privacy_policy from './components/terms_policy/Privacy_policy';
import Faq from './components/terms_policy/Faq';
import Attach_taxi from './components/attach-taxi/Attach_taxi';
import Where_is_cab from './components/where_is_cab/Where_is_cab';
import Packages from './components/packages/Packages';
import Give_rating_modal from './components/common-components/modals/give-rating-modal/Give_rating_modal';
import PopularRide from './components/popular-ride/PopularRide';

function App() {

  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      {/* <ScrollToTop /> */}

      {/* <Give_rating_modal /> */}

      {pathname === "/login" || pathname === "/signup" || pathname === "/reset-password" || pathname === "/otp" || pathname === "/set-new-password" || pathname === "/password-reset-successfully" ? "" : <Header />}   

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<About_us />} />
        <Route path="/routes" element={<RoutesPage />} />
        <Route path="/routes-inner" element={<RoutesInner />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/our-services" element={<Our_services />} />
        <Route path="/booking-form" element={<Booking_form />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blogs-inner" element={<BlogInner />} />
        <Route path="/select-ride" element={<Select_ride />} />
        <Route path="/book-history" element={<BookingHistory />} />
        <Route path="/package-details" element={<PackageDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/account-setting" element={<Account_setting />} />
        <Route path="/edit-account-setting" element={<Edit_account_setting />} />
        <Route path="/otp" element={<Set_Otp />} />
        <Route path="/set-new-password" element={<SetNewPassword />} />
        <Route path="/terms-conditions" element={<Terms_conditions />} />
        <Route path="/privacy-policy" element={<Privacy_policy />} />
        <Route path="/password-reset-successfully" element={<Password_reset_successfully />} />
        <Route path="/disclainer" element={<Disclaimer />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/attach-taxi" element={<Attach_taxi />} />
        <Route path="/where-cab" element={<Where_is_cab />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/popular-ride" element={<PopularRide />} />
      </Routes>

      {pathname === "/login" || pathname === "/signup" || pathname === "/reset-password" || pathname === "/otp" || pathname === "/set-new-password" || pathname === "/password-reset-successfully" ? ""  : <Footer />}

    </>
  );
}

export default App;

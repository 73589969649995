import React, { useEffect, useState } from 'react'

import Package_Banner from './package-banner/Package_Banner';
import Icon_cab from '../home/icon-cab/Icon_cab';
import PackageCards from './package-cards/PackageCards';
import Package_Form from './package-form/Package_Form';

const Packages = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <>
      <div className='packages_main_sec packagebgg'>

        <Package_Banner />

        <PackageCards />

      </div>
      <Icon_cab />
    </>
  )
}

export default Packages
import React from 'react'
import './Abt_whychoose.css'

const Abt_whychoose = () => {
    return (
        <>
            <section className='Abt_whychoose_sec'>
                <div className='titlediv'>
                    <h6 className='titlewhy'>Why Choose Icon Cabs?</h6>
                </div>

                <div className='container-fluid p-0'>
                    <h1 className='bigtitle'>Why Choose Icon Cabs?</h1>    
                    <div className='choosesec'>
                        <div className='row  mx-auto justify-content-center '>
                            <div className='col-xl-2  col-md-3 col-sm-4 col-6 mb-4'>
                                <div className='choose-card'>
                                    <div className='icon-sec'>
                                        <div className='icondiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/about/why-choose/24-hour-service.png'} />
                                        </div>
                                        <h6 className='cardtitle'>24/7 Availability</h6>
                                    </div>
                                    <div className='contdiv'>
                                        <p className='text'>Whether it’s an early morning
                                            flight or a late-night ride, we’re
                                            here for you around the clock.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2  col-md-3 col-sm-4 col-6 mb-4'>
                                <div className='choose-card'>
                                    <div className='icon-sec'>
                                        <div className='icondiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/about/why-choose/affordablerate.png'} />
                                        </div>
                                        <h6 className='cardtitle'>Affordable Rate</h6>
                                    </div>
                                    <div className='contdiv'>
                                        <p className='text'>Quality service doesn’t have to
                                            break the bank. We offer competitive
                                            pricing for all your transportation
                                            needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2  col-md-3 col-sm-4 col-6 mb-4'>
                                <div className='choose-card'>
                                    <div className='icon-sec'>
                                        <div className='icondiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/about/why-choose/car.png'} />
                                        </div>
                                        <h6 className='cardtitle'>Clean & Comfortable Rides</h6>
                                    </div>
                                    <div className='contdiv'>
                                        <p className='text'>Experience a ride that’s not only
                                            smooth but also clean and well
                                            maintained for your comfort.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2  col-md-3 col-sm-4 col-6 mb-4'>
                                <div className='choose-card'>
                                    <div className='icon-sec'>
                                        <div className='icondiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/about/why-choose/drivers.png'} />
                                        </div>
                                        <h6 className='cardtitle'>Professional & friendly Drivers</h6>
                                    </div>
                                    <div className='contdiv'>
                                        <p className='text'>Our drivers are trained, courteous,
                                            and always ready to assist you
                                            with a smile.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-2  col-md-3 col-sm-4 col-6 mb-4'>
                                <div className='choose-card'>
                                    <div className='icon-sec'>
                                        <div className='icondiv'>
                                            <img className='icon' src={process.env.PUBLIC_URL + '/assets/images/about/why-choose/carchecklist.png'} />
                                        </div>
                                        <h6 className='cardtitle'>Punctuality You Can Trust</h6>
                                    </div>
                                    <div className='contdiv'>
                                        <p className='text'>We prioritize being on time,
                                            every time. Your schedule is
                                            important to us</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <img className='carbg' src={process.env.PUBLIC_URL + '/assets/images/about/why-choose/carbg.png'} />
                </div>
            </section>
        </>
    )
}

export default Abt_whychoose
import React, { useRef, useState } from 'react';
import './Happy_customers.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import ReactStars from "react-rating-stars-component";
import { render } from "react-dom";

const Happy_customers = () => {

    const ratingChanged = (newRating) => {
        console.log(newRating);
    };


    return (
        <>
            <section className='Happy_customers_sec'>
                <div className='container'>
                    <div className='titlediv'>
                        <h4 className='title'>Hear</h4>
                        <h6 className='subtitle'>What our Happy Customers have to say!</h6>
                    </div>
                    <div className='slidersec '>
                        <Swiper
                           
                            spaceBetween={10}
                            centeredSlides={true}
                            navigation={true}
                            // pagination={{
                            //     clickable: false,
                            // }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay ,  Navigation]}

                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 50,
                                },
                            }}
                       
                        >
                            <SwiperSlide>
                                <div className='sliderdiv'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='imgdiv'>
                                                <img className='sliderimg' src={process.env.PUBLIC_URL + '/assets/images/home/happy-customer/client.png'} />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <div className='content-sec'>
                                                <h6 className='custname'>Komal Nathe</h6>
                                                <p className='custcont'>Using this website to book my rides has been a breeze!
                                                    The interface is user-friendly, and I love how quickly I
                                                    can find a cab. The real-time tracking feature gives me
                                                    peace of mind, knowing exactly when my driver will arrive
                                                    It's a reliable service that I now use regularly</p>

                                                <p className='cust-add'><span>-</span>-Nashik, Maharashtra</p>
                                                <ReactStars
                                                    count={5}
                                                    onChange={ratingChanged}
                                                    size={20}
                                                    activeColor="#ffd700"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='sliderdiv'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='imgdiv'>
                                                <img className='sliderimg' src={process.env.PUBLIC_URL + '/assets/images/home/happy-customer/client.png'} />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <div className='content-sec'>
                                                <h6 className='custname'>Komal Nathe</h6>
                                                <p className='custcont'>Using this website to book my rides has been a breeze!
                                                    The interface is user-friendly, and I love how quickly I
                                                    can find a cab. The real-time tracking feature gives me
                                                    peace of mind, knowing exactly when my driver will arrive
                                                    It's a reliable service that I now use regularly</p>

                                                <p className='cust-add'><span>-</span>-Nashik, Maharashtra</p>
                                                <ReactStars
                                                    count={5}
                                                    onChange={ratingChanged}
                                                    size={20}
                                                    activeColor="#ffd700"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='sliderdiv'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='imgdiv'>
                                                <img className='sliderimg' src={process.env.PUBLIC_URL + '/assets/images/home/happy-customer/client.png'} />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <div className='content-sec'>
                                                <h6 className='custname'>Komal Nathe</h6>
                                                <p className='custcont'>Using this website to book my rides has been a breeze!
                                                    The interface is user-friendly, and I love how quickly I
                                                    can find a cab. The real-time tracking feature gives me
                                                    peace of mind, knowing exactly when my driver will arrive
                                                    It's a reliable service that I now use regularly</p>

                                                <p className='cust-add'><span>-</span>-Nashik, Maharashtra</p>
                                                <ReactStars
                                                    count={5}
                                                    onChange={ratingChanged}
                                                    size={20}
                                                    activeColor="#ffd700"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='sliderdiv'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <div className='imgdiv'>
                                                <img className='sliderimg' src={process.env.PUBLIC_URL + '/assets/images/home/happy-customer/client.png'} />
                                            </div>
                                        </div>
                                        <div className='col-8'>
                                            <div className='content-sec'>
                                                <h6 className='custname'>Komal Nathe</h6>
                                                <p className='custcont'>Using this website to book my rides has been a breeze!
                                                    The interface is user-friendly, and I love how quickly I
                                                    can find a cab. The real-time tracking feature gives me
                                                    peace of mind, knowing exactly when my driver will arrive
                                                    It's a reliable service that I now use regularly</p>

                                                <p className='cust-add'><span>-</span>-Nashik, Maharashtra</p>
                                                <ReactStars
                                                    count={5}
                                                    onChange={ratingChanged}
                                                    size={20}
                                                    activeColor="#ffd700"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>



                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Happy_customers
import React, { useEffect } from 'react'
import Banner from '../home/banner/Banner'
import Icon_cab from '../home/icon-cab/Icon_cab'
import InnerBanner from './Inner-Banner/InnerBanner';
import BookCab from './cab-book/BookCab';
import CabFare from './cab-fare/CabFare';
import TaxiService from './taxi-service/TaxiService';
import CabRoutes from './cab-routes/CabRoutes';
import PopularRideBanner from './popular-ride-banner/PopularRideBanner';

const PopularRide = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);    

    return (
        <>
            <Banner />
            <PopularRideBanner/>
            <InnerBanner/>
            <BookCab/>
            <CabFare/>
            <TaxiService/>
            <CabRoutes/>
            <Icon_cab />

        </>
    )
}

export default PopularRide

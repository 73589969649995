import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Booking_breadcrumb = () => {
    return (
        <>
            <section className='breadcrumb-div'>
                <div className='container'>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Our service</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Outstation</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">One way</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </section>
        </>
    )
}

export default Booking_breadcrumb
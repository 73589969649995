import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import './BookingHistory.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { faLocationDot, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons';
import PackageHistory from './packagehistory/PackageHistory';
import { Link } from 'react-router-dom';


const BookingHistory = () => {

    const [swiperInstance, setSwiperInstance] = useState(null);
    const [swiperInstance2, setSwiperInstance2] = useState(null);
    const [swiperInstance3, setSwiperInstance3] = useState(null);

    const handleMainSliderClick = (direction) => {
        console.log("swiperInstance",swiperInstance)
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };
// State to hold swiper instance

  const handleMainSliderClick2 = (direction) => {
    console.log("swiperInstance2", swiperInstance2);
    if (swiperInstance2) {
      if (direction === "prev") {
        swiperInstance2.slidePrev(); // Navigate to the previous slide
      } else if (direction === "next") {
        swiperInstance2.slideNext(); // Navigate to the next slide
      }
    }
  };


    const handleMainSliderClick3 = (direction) => {
        console.log("swiperInstance3",swiperInstance3)

        if (swiperInstance3) {
            if (direction === "prev") {
                swiperInstance3.slidePrev();
            } else if (direction === "next") {
                swiperInstance3.slideNext();
            }
        }
    };



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { pathname } = useLocation();
    // Booking data array
    const bookings = [
        {
            carName: 'Indica',
            route: 'Pune To Mumbai',
            date: '14 June',
            time: '4:45 AM',
            price: '₹. 594',
            imgSrc: process.env.PUBLIC_URL + '/assets/images/booking-story/gadi.png'
        },
        {
            carName: 'Indica',
            route: 'Pune To Mumbai',
            date: '14 June',
            time: '4:45 AM',
            price: '₹. 594',
            imgSrc: process.env.PUBLIC_URL + '/assets/images/booking-story/gadi.png'
        },
        {
            carName: 'Indica',
            route: 'Pune To Mumbai',
            date: '14 June',
            time: '4:45 AM',
            price: '₹. 594',
            imgSrc: process.env.PUBLIC_URL + '/assets/images/booking-story/gadi.png'
        },
        {
            carName: 'Indica',
            route: 'Pune To Mumbai',
            date: '14 June',
            time: '4:45 AM',
            price: '₹. 594',
            imgSrc: process.env.PUBLIC_URL + '/assets/images/booking-story/gadi.png'
        },
    ];

    return (
        <section className='booking-history'>
            <img src={process.env.PUBLIC_URL + '/assets/images/booking-story/history-banner.png'} className='history-book-banner' />
            <Container>
                <div className='white-bggg'>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4></h4>
                        <h4 className='booking-history-title text-center flex-grow-1'>Booking History</h4>
                        <h5><FontAwesomeIcon icon={faCalendar} /></h5>
                    </div>

                    <div className='dark-grey-bggg'>
                        <div className='text-center mb-4'>
                            <h5 className='history-title'>Outstation Services</h5>
                        </div>
                        <Row>
                            <Swiper
                                slidesPerView={3.5}
                                spaceBetween={15}
                                className="mySwiper history-slider"
                                onSwiper={(swiper) =>
                                    setSwiperInstance(swiper)
                                  }
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    485: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    576: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    640: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 20,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    1200: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 20,
                                    },
                                    1440: {
                                        slidesPerView: 3,
                                        spaceBetween: 15,
                                    },
                                }}
                            >
                                {bookings.map((booking, index) => (
                                    <SwiperSlide key={index} className='mb-5'>
                                        <div className="history-slide">
                                            <Row>
                                                <Col lg={6} md={6} sm={6} xs={12}>
                                                    <img src={booking.imgSrc} className='car-imggg' alt={booking.carName} />
                                                    <p className='car-name'>{booking.carName}</p>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={12}>
                                                    <p className='txttt-hisory'><FontAwesomeIcon icon={faLocationDot} className='me-2' />{booking.route}</p>
                                                    <p className='txttt-hisory'><FontAwesomeIcon icon={faCalendar} className='me-2' />{booking.date}</p>
                                                    <p className='txttt-hisory'><FontAwesomeIcon icon={faClock} className='me-2' />{booking.time}</p>
                                                    <div className='brown-border'></div>
                                                    <p className='red-txttt'><FontAwesomeIcon icon={faMoneyBill1Wave} className='me-2' />{booking.price}</p>
                                                </Col>
                                            </Row>
                                            <Link to="/our-services"><button className='rebook-btnnnn'><span>Rebook</span></button></Link>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            <div class="silder-btn">
                                <div
                                    className="back-btn"
                                    onClick={() => handleMainSliderClick("prev")}
                                >
                                    <img className='abt-banner' src={process.env.PUBLIC_URL + '/assets/images/booking-story/leftarrow.png'} />
                                </div>
                                <div
                                    className="next-btn"
                                    onClick={() => handleMainSliderClick("next")}
                                >
                                    <img className='abt-banner' src={process.env.PUBLIC_URL + '/assets/images/booking-story/rightarrow.png'} />
                                </div>
                            </div>
                        </Row>
                    </div>
                    <div className='dark-grey-bggg'>
                        <div className='text-center mb-4'>
                            <h5 className='history-title'>Outstation Services</h5>
                        </div>
                        <Row>
                            <Swiper
                               slidesPerView={3.5}
                               spaceBetween={15}
                               className="mySwiper history-slider"
                               onSwiper={(swiper) => setSwiperInstance2(swiper)} 
                               breakpoints={{
                                   320: {
                                       slidesPerView: 1,
                                       spaceBetween: 10,
                                   },
                                   485: {
                                       slidesPerView: 1,
                                       spaceBetween: 10,
                                   },
                                   576: {
                                       slidesPerView: 1,
                                       spaceBetween: 10,
                                   },
                                   640: {
                                       slidesPerView: 1.5,
                                       spaceBetween: 20,
                                   },
                                   1024: {
                                       slidesPerView: 2,
                                       spaceBetween: 20,
                                   },
                                   1200: {
                                       slidesPerView: 2.5,
                                       spaceBetween: 20,
                                   },
                                   1440: {
                                       slidesPerView: 3,
                                       spaceBetween: 15,
                                   },
                               }}
                            >
                                {bookings.map((booking, index) => (
                                    <SwiperSlide key={index} className='mb-5'>
                                        <div className="history-slide">
                                            <Row>
                                                <Col lg={6} md={6} sm={6} xs={12}>
                                                    <img src={booking.imgSrc} className='car-imggg' alt={booking.carName} />
                                                    <p className='car-name'>{booking.carName}</p>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={12}>
                                                    <p className='txttt-hisory'><FontAwesomeIcon icon={faLocationDot} className='me-2' />{booking.route}</p>
                                                    <p className='txttt-hisory'><FontAwesomeIcon icon={faCalendar} className='me-2' />{booking.date}</p>
                                                    <p className='txttt-hisory'><FontAwesomeIcon icon={faClock} className='me-2' />{booking.time}</p>
                                                    <div className='brown-border'></div>
                                                    <p className='red-txttt'><FontAwesomeIcon icon={faMoneyBill1Wave} className='me-2' />{booking.price}</p>
                                                </Col>
                                            </Row>
                                            <Link to="/our-services"><button className='rebook-btnnnn'><span>Rebook</span></button></Link>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div class="silder-btn">
                                <div
                                    className="back-btn"
                                    onClick={() => handleMainSliderClick2("prev")}
                                >
                                    <img className='abt-banner' src={process.env.PUBLIC_URL + '/assets/images/booking-story/leftarrow.png'} />
                                </div>
                                <div
                                    className="next-btn"
                                    onClick={() => handleMainSliderClick2("next")}
                                >
                                    <img className='abt-banner' src={process.env.PUBLIC_URL + '/assets/images/booking-story/rightarrow.png'} />
                                </div>
                            </div>
                        </Row>

                    </div>
                    <div className='dark-grey-bggg'>
                        <div className='text-center mb-4'>
                            <h5 className='history-title'>Outstation Services</h5>
                        </div>
                        <Row>
                            <Swiper
                                slidesPerView={3.5}
                                onSwiper={(swiper) =>
                                    setSwiperInstance3(swiper)
                                  }
                                spaceBetween={15}
                                className="mySwiper history-slider"
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    485: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    576: {
                                        slidesPerView: 1,
                                        spaceBetween: 10,
                                    },
                                    640: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 20,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    1200: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 20,
                                    },
                                    1440: {
                                        slidesPerView: 3,
                                        spaceBetween: 15,
                                    },
                                }}
                            >
                                {bookings.map((booking, index) => (
                                    <SwiperSlide key={index} className='mb-5'>
                                        <div className="history-slide">
                                            <Row>
                                                <Col lg={6} md={6} sm={6} xs={12}>
                                                    <img src={booking.imgSrc} className='car-imggg' alt={booking.carName} />
                                                    <p className='car-name'>{booking.carName}</p>
                                                </Col>
                                                <Col lg={6} md={6} sm={6} xs={12}>
                                                    <p className='txttt-hisory'><FontAwesomeIcon icon={faLocationDot} className='me-2' />{booking.route}</p>
                                                    <p className='txttt-hisory'><FontAwesomeIcon icon={faCalendar} className='me-2' />{booking.date}</p>
                                                    <p className='txttt-hisory'><FontAwesomeIcon icon={faClock} className='me-2' />{booking.time}</p>
                                                    <div className='brown-border'></div>
                                                    <p className='red-txttt'><FontAwesomeIcon icon={faMoneyBill1Wave} className='me-2' />{booking.price}</p>
                                                </Col>
                                            </Row>
                                            <Link to="/our-services"><button className='rebook-btnnnn'><span>Rebook</span></button></Link>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div class="silder-btn">
                                <div
                                    className="back-btn"
                                    onClick={() => handleMainSliderClick3("prev")}
                                >
                                    <img className='abt-banner' src={process.env.PUBLIC_URL + '/assets/images/booking-story/leftarrow.png'} />
                                </div>
                                <div
                                    className="next-btn"
                                    onClick={() => handleMainSliderClick3("next")}
                                >
                                    <img className='abt-banner' src={process.env.PUBLIC_URL + '/assets/images/booking-story/rightarrow.png'} />
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
                <PackageHistory />
            </Container>
        </section>
    );
}

export default BookingHistory;

import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom"
import "./banner/Banner.css";
import Banner from "./banner/Banner";
import Attach_taxi from "./attach-taxi/Attach_taxi";
import Our_services from "./our-services/Our_services";
import Key_services from "./key-services/Key_services";
import Happy_customers from "./happy-customers/Happy_customers";
import Why_ride from "./why-ride/Why_ride";
import Icon_cab from "./icon-cab/Icon_cab";

function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);    
  const { pathname } = useLocation();
  
  return (
    <>
      <Banner />
      <Attach_taxi />
      <Our_services />
      <Key_services />
      <Happy_customers />
      <Why_ride />
      <Icon_cab />
    </>
  );
}

export default Home;

import React, { useState } from "react";
import "./Banner.css";
import axios from "axios";

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TimePicker } from "antd";
import Enter_phone_modal from "../../common-components/modals/Enter-phone/Enter_phone_modal";



function Banner() {
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ];

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [endDate2, setEndDate2] = useState();
  const [endDate3, setEndDate3] = useState();
  const [endDate4, setEndDate4] = useState();

  const [modalShow, setModalShow] = React.useState(false);
  return (
    <section className="home-banner-sec">
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-10 col-md-11 mx-auto">
            <div className="form-mainsec">
              <div className="top-tilediv">
                <h6 className="topt">Your Journey, Our Drive </h6>
                <p className="subtext">Experience Comfort on the Move.</p>
              </div>

              <div className="fomdiv">
                <div className="maintabs">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                    <div className="row">
                      <div className="col-xl-8 col-md-11 mx-auto">
                        <Nav variant="pills" className="">
                          <Nav.Item>
                            <Nav.Link eventKey="first">Outstation</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Local Hourly Package</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">Airport Transfer</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </div>

                    <Tab.Content>
                      <Tab.Pane eventKey="first">

                        <div className="firsttabinner">
                          <Tab.Container id="left-tabs-example" defaultActiveKey="firstone">

                            <div className="row">
                              <div className="col-xl-7 col-md-10 mx-auto">
                                <Nav variant="pills" className="">
                                  <Nav.Item>
                                    <Nav.Link eventKey="firstone">One way</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="secondtwo">Round Trip</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="thirdthree">Multicity</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                            </div>

                            <Tab.Content>
                              <Tab.Pane eventKey="firstone">
                                <div className="formbox">
                                  <div className="row">
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Source City</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Destination City</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Date Of Departure</Form.Label>
                                        <DatePicker
                                          selected={endDate}
                                          onChange={(date) => setEndDate(date)}
                                          name="deadline"
                                          id="deadline"
                                          autoComplete="off"
                                          className="form-control"
                                          placeholderText="MM/DD/YYYY"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Time Of Departure</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="secondtwo">
                                <div className="formbox">
                                  <div className="row">
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Source City</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Destination City</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Date Of Departure</Form.Label>
                                        <DatePicker
                                          selected={endDate2}
                                          onChange={(date) => setEndDate2(date)}
                                          name="deadline"
                                          id="deadline"
                                          autoComplete="off"
                                          className="form-control"
                                          placeholderText="MM/DD/YYYY"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Time Of Departure</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Returning the cab to</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Journey end date</Form.Label>
                                        <DatePicker
                                          selected={endDate3}
                                          onChange={(date) => setEndDate3(date)}
                                          name="deadline"
                                          id="deadline"
                                          autoComplete="off"
                                          className="form-control"
                                          placeholderText="MM/DD/YYYY"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Journey end time</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="thirdthree">
                                <div className="formbox">
                                  <div className="row">
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Source City</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Destination City</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Date Of Departure</Form.Label>
                                        <DatePicker
                                          selected={endDate4}
                                          onChange={(date) => setEndDate4(date)}
                                          name="deadline"
                                          id="deadline"
                                          autoComplete="off"
                                          className="form-control"
                                          placeholderText="MM/DD/YYYY"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Time Of Departure</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>


                          </Tab.Container>
                        </div>

                      </Tab.Pane>


                      <Tab.Pane eventKey="second">
                        <div className="firsttabinner">
                          <Tab.Container id="left-tabs-example" defaultActiveKey="firstone">

                            <div className="row">
                              <div className="col-lg-7 col-md-10 mx-auto">
                                <Nav variant="pills" className="">
                                  <Nav.Item>
                                    <Nav.Link eventKey="firstone">Daily rental on hourly basis </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="secondtwo">Pick-up fom Airport</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="thirdthree">Drop-off from Airport</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                            </div>

                            <Tab.Content>
                              <Tab.Pane eventKey="firstone">
                                <div className="formbox">
                                  <div className="row">
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Pickup City</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Select length of time </Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Date Of Pickup</Form.Label>
                                        <DatePicker
                                          selected={endDate}
                                          onChange={(date) => setEndDate(date)}
                                          name="deadline"
                                          id="deadline"
                                          autoComplete="off"
                                          className="form-control"
                                          placeholderText="MM/DD/YYYY"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Time Of Pickup</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="secondtwo">
                                <div className="formbox">
                                  <div className="row">
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Which airport are we pickup from</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Going to location</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Date Of Pickup</Form.Label>
                                        <DatePicker
                                          selected={endDate}
                                          onChange={(date) => setEndDate(date)}
                                          name="deadline"
                                          id="deadline"
                                          autoComplete="off"
                                          className="form-control"
                                          placeholderText="MM/DD/YYYY"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Time Of Pickup</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="thirdthree">
                                <div className="formbox">
                                  <div className="row">
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Which airport are we dropping you at</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Pickup location</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Date Of Pickup</Form.Label>
                                        <DatePicker
                                          selected={endDate}
                                          onChange={(date) => setEndDate(date)}
                                          name="deadline"
                                          id="deadline"
                                          autoComplete="off"
                                          className="form-control"
                                          placeholderText="MM/DD/YYYY"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Time Of Pickup</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>


                          </Tab.Container>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="third">
                        <div className="firsttabinner">
                          <Tab.Container id="left-tabs-example" defaultActiveKey="firstone">

                            <div className="row">
                              <div className="col-md-10 mx-auto">
                                <Nav variant="pills" className="">
                                  <Nav.Item>
                                    <Nav.Link eventKey="firstone">Drop-off from Airport(Local) </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="secondtwo">Pick-off from Airport(Local)</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="thirdthree">Drop-off from Airport(Outstation)</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="fourthfour">Pick-up from Airport(Outstation)</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                            </div>

                            <Tab.Content>
                              <Tab.Pane eventKey="firstone">
                                <div className="formbox">
                                  <div className="row">
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Which airport are we pickup from</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Goint to location</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Date Of Pickup</Form.Label>
                                        <DatePicker
                                          selected={endDate}
                                          onChange={(date) => setEndDate(date)}
                                          name="deadline"
                                          id="deadline"
                                          autoComplete="off"
                                          className="form-control"
                                          placeholderText="MM/DD/YYYY"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Time Of Pickup</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="secondtwo">
                                <div className="formbox">
                                  <div className="row">
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Which airport are we Dropping you at</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Pickup location</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Date Of Pickup</Form.Label>
                                        <DatePicker
                                          selected={endDate}
                                          onChange={(date) => setEndDate(date)}
                                          name="deadline"
                                          id="deadline"
                                          autoComplete="off"
                                          className="form-control"
                                          placeholderText="MM/DD/YYYY"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Time Of Pickup</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="thirdthree">
                                <div className="formbox">
                                  <div className="row">
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Which airport are you need the pickup from</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Going to location</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Date Of Pickup</Form.Label>
                                        <DatePicker
                                          selected={endDate}
                                          onChange={(date) => setEndDate(date)}
                                          name="deadline"
                                          id="deadline"
                                          autoComplete="off"
                                          className="form-control"
                                          placeholderText="MM/DD/YYYY"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Time Of Pickup</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>

                              <Tab.Pane eventKey="fourthfour">
                                <div className="formbox">
                                  <div className="row">
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Pickup city</Form.Label>
                                        <Select options={options} />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Which airport do you need the dropping at?</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Date Of Pickup</Form.Label>
                                        <DatePicker
                                          selected={endDate}
                                          onChange={(date) => setEndDate(date)}
                                          name="deadline"
                                          id="deadline"
                                          autoComplete="off"
                                          className="form-control"
                                          placeholderText="MM/DD/YYYY"
                                        />
                                      </Form.Group>
                                    </div>
                                    <div className="col-lg-4 mb-2">
                                      <Form.Group className="formgroup">
                                        <Form.Label>Time Of Pickup</Form.Label>
                                        <TimePicker
                                          name="start_time"
                                          id="start_time"
                                          autoComplete="off"
                                          className="form-control"
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>


                          </Tab.Container>
                        </div>
                      </Tab.Pane>

                    </Tab.Content>

                  </Tab.Container>
                </div>

                <div className="text-center">
                  <button className="btn bookbbtn" type="button" onClick={() => setModalShow(true)}><span>Book Your Ride</span></button>
                </div>

                <Enter_phone_modal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>




  );
}

export default Banner;
